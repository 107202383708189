export const ymd = (t) => {
    t = new Date(t)
    let y = t.getFullYear().toString()
    let m = t.getMonth()+1
        m = ((m>9)? "" : "0" ) + m.toString()
    let d = t.getDate()
        d = ((d>9)? "" : "0" ) + d.toString()
    t = [y +"-"+ m +"-"+ d]
    return (<span>{t}</span>)
}

export const dmy = (t) => {
    t = new Date(t)
    let y = t.getFullYear().toString()
    let m = t.getMonth()+1
        m = ((m>9)? "" : "0" ) + m.toString()
    let d = t.getDate()
        d = ((d>9)? "" : "0" ) + d.toString()
    t = [d +"-"+ m +"-"+ y]
    return (<span>{t}</span>)
}