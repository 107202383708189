import React, { Component } from 'react'
import { Story, VisionMission, MentorsGrid } from "../components";

export class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bc : false,
        };
    }

    componentDidMount() {
        this.setBreadcrumb()
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.update===true){
            
        }

        this.setBreadcrumb()
    }

    setBreadcrumb(){
        const { bc } = this.state
        const { main, setBreadcrumb } = this.props

        if(bc===false && main.language){
            var title = (main.language.MENU_ABOUT_US)? main.language.MENU_ABOUT_US : "Title"
            
            setBreadcrumb(title, [{name:title, link:"/about"}])
            this.setState({ bc:true })
        }
    }

    render() {
        const { main } = this.props
        return (
            <main>
                <div role="main" className="main">
                    <Story {...this.props} />
                    <VisionMission visiMisiTag={{removeHeader:true}} {...this.props} />
                    <MentorsGrid MentoGridTag={{ id:"Team", title:main.language.MENU_TEAM }} {...this.props} />
                </div>
            </main>
        )
    }
}

export default About
