import React, { Component } from "react";

export class P404 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bc : false
    };
  }

  componentDidMount() {
      this.setBreadcrumb()
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.update===true){
          
      }

      this.setBreadcrumb()
  }

  setBreadcrumb(){
      const { bc } = this.state
      const { setBreadcrumb } = this.props
      
      if(bc===false){
          setBreadcrumb("Home", [])
          this.setState({ bc:true })
      }
  }

  render() {
    return (
      <main>
        <div role="main" className="main">
            <div className="page-content-wrapper section-space--inner--120">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-6 ml-auto mr-auto">
                          <div className="not-found-page-content text-center section-space--bottom--50">
                              <h2 className="not-found__page-title">404</h2>
                              <h3 className="not-found__page-subtitle">OOPS! That page can be not found!</h3>
                              <p className="not-found__page-text">Leverage agile frameworks to provide a robust synopsis for high level.</p>
                          </div>
                          <div className="not-found__page-search text-center">
                              <form action="/home">
                                  <input type="text" placeholder="Keyword Search . . "/>
                                  <button type="submit"><i className="ion-ios-search-strong"></i></button>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </main>
    );
  }
}

export default P404;
