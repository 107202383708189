import React, { Component } from "react";
import { getClientLanding } from '../utils/request';
import DataTable from 'react-data-table-component';
import { withRouter } from "react-router";

export class Tenant extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tenant : [],
			width : "100%",
			height : "150px",
            update : true,

            filter : "",
            currentPage : 1,
            perPage : 10,
            columns : this.columns(),
        };
    }

    componentDidMount() {
        getClientLanding().then((res)=>{
            this.setState({ tenant : res })
        })
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    columns(){
        return [
            { name: 'No', maxWidth:"5%", selector: (t,i) => {
                const { currentPage, perPage } = this.state
                return ( perPage * (currentPage-1) + (i+1) )
            }, },
            { name: 'Tenant', selector: t => t.NAME, sortable: true, },
            { name: 'Email', selector: t => t.EMAIL, sortable: true, },
            { name: 'Contact', selector: t => t.PHONE_NO, sortable: true, },
            { name: 'Social Media', maxWidth:"15%", center:true, selector: t => (
                <div>
                    <a href={t.LINK_IG} target="_blank" rel="noreferrer" title="Instagram">
                        <i className="ion-social-instagram" style={{fontSize:"20px"}}></i>
                    </a>
                    &nbsp;
                    &nbsp;
                    <a href={t.LINK_YT} target="_blank" rel="noreferrer" title="Youtube">
                        <i className="ion-social-youtube" style={{fontSize:"20px"}}></i>
                    </a>
                </div>
            ), },
        ]
    }

    render() {
        const { main } = this.props
        const { columns, perPage, filter } = this.state
        let { tenant } = this.state
        const title = (this.props.tenantTag!==undefined)? this.props.tenantTag.title
            : (main.language)? main.language.MENU_TENANT : "-"
        
        if(filter!==""){
            tenant = tenant.filter( t => t.NAME.toLowerCase().includes(filter) || t.EMAIL.toLowerCase().includes(filter) || t.PHONE_NO.toLowerCase().includes(filter) )
        }

        return (
            <div className="page-content-wrapper section-space--inner--120" id="Tenant">
                <div className="container">
                    {(this.props.tenantTag!==undefined && this.props.tenantTag.removeHeader===true)?
                        <div></div>
                    :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-title-area text-center section-space--bottom--50">
                                    <h2 className="title">{ title }</h2>
                                    <p className="subtitle">Professional ethics govern the values of social responsibility that help us deliver our obligations within a coherent social framework.</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common-page-content">                                
                                <div className="brand-logo-grid__wrapper section-space--bottom--50">
                                    <div className="row">
                                        <div className="col-8">
                                        </div>
                                        <div className="col-4">
                                            <input id="search" type="text" placeholder="Filter" aria-label="Search Input" className="form-control" onChange={(e)=>{ this.setState({ filter:e.target.value }) }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <DataTable
                                            columns={columns}
                                            data={tenant}
                                            pagination
                                            persistTableHead
                                            responsive
                                            paginationRowsPerPageOptions={[5,10,15]}
                                            paginationPerPage={perPage}
                                            customStyles={{rows:{style:{cursor:"pointer",'&:hover':{backgroundColor:"whitesmoke"}}}}}
                                            onChangePage={(page, totalRows)=>{ this.setState({ currentPage : page, columns : this.columns() }) }}
                                            onRowClicked={(data,target)=> {
                                                this.props.history.push('/tenant/'+data.SLUG)
                                                setTimeout(function(){
                                                    window.scrollTo({ top: 0, behavior: 'smooth' })
                                                },1000)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Tenant)