// export const API_URL = "https://pro-visioner.com/admin/";
export const API_URL = "https://admin.web.ecosystem.penggiat-riset2.com/";

export const API_MAIN = API_URL + "main/apiGetMain/";
export const API_BANNERS = API_URL + "sliders/apiGetSliders/";
export const API_FAQ_LANDING = API_URL + "faqs/apiGetFaqsForLanding/";
export const API_STEP_LANDING = API_URL + "strategies/apiGetStrategiesForLanding";
export const API_CLIENT_LANDING = API_URL + "clients/apiGetClientsForLanding/";
export const API_DETAIL_CLIENT = API_URL + "clients/apiGetClientsBySlug/";

export const API_SERVICES = API_URL + "services/apiGetServicesForLanding/";
export const API_DETAIL_SERVICE = API_URL + "services/apiGetServicesBySlug/";

export const API_CASE_TAGS = API_URL + "projects/apiGetProjectsTags/";
export const API_CASES = API_URL + "projects/apiGetProjects/";
export const API_DETAIL_CASE = API_URL + "projects/apiGetProjectsBySlug/";

export const API_NEWS = API_URL + "news/apiGetNews/";
export const API_NEWS_LANDING = API_URL + "news/apiGetNewsForLanding/";
export const API_DETAIL_NEWS = API_URL + "news/apiGetNewsBySlug/";
export const API_NEWS_NEXT_PREV = API_URL + "news/apiGetNextPrevNewsBySlug/";
export const API_NEWS_CATEGORY = API_URL + "categorys/apiGetCategorys";

export const API_PERSON = API_URL + "person/apiGetPerson/";
export const API_PERSON_EXPERT = API_URL + "person/apiGetPersonExpert/";
export const API_PERSON_LEADER = API_URL + "person/apiGetPersonLeader/";
export const API_DETAIL_PERSON = API_URL + "person/apiGetPersonBySlug/";

export const API_PAGES = API_URL + "pages/apiGetPages/";
export const API_DETAIL_PAGE = API_URL + "pages/apiGetPagesBySlug/";
export const API_DETAILS_PAGE = API_URL + "pages_detail/apiGetPagesDetailPagesID/";

export const API_CAREER = API_URL + "careers/apiGetCareers/";
export const API_DETAIL_CAREER = API_URL + "careers/apiGetCareersBySlug/";
export const API_SUBMIT_LAMARAN = API_URL + "careers/apiSubmitLamaran";

export const API_SUBCRIBE = API_URL + "subscribers/apiPostSubscribe"
export const API_POST_MESSAGE = API_URL + "subscribers/apiPostSendMessage";
export const API_SET_LANGUAGE = API_URL + "main/apiPostSettingLang";
export const API_GET_LANGUAGE = API_URL + "main/apiGetSettingLang";

export const API_GET_CLIENT_PRODUCT = API_URL + "client_products/apiGetClientsProducts";
export const API_GET_CLIENT_PRODUCT_LANDING = API_URL + "client_products/apiGetClientsProductsForLanding";
export const API_DETAIL_CLIENT_PRODUCT = API_URL + "client_products/apiGetClientsProductsBySlug";
export const API_CLIENT_PRODUCT_BY_CLIENT = API_URL + "client_products/apiGetClientsProductsByClientId";

export const API_PORTFOLIO_CLIENT = API_URL + "clients_portofolios/apiGetClientsPortofoliosByClientId";

export const API_SERVICE_CLIENT = API_URL + "clients_services/apiGetClientsServicesByClientId";

export const API_GET_DOCUMENT = API_URL + "documents/apiGetDocumentForLanding";

export const API_EVENTS = API_URL + "events/apiGetEvents/";
export const API_DETAIL_EVENTS = API_URL + "events/apiGetEventsBySlug/";

export const API_STORYLINE = API_URL + "story_line/apiGetStoryLine/";

