import React, { Component } from "react";
import { appendScript, removeElement } from '../utils/dom';

export class Statistic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "StatisticScript",
                src : "/js/component/Statistic.js",
                async : true,
            },
            remove : ".StatisticScript", // string or array
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
        appendScript(attrs,remove)
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }
      
    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.update===true){
        this.componentDidMount()
      }
    }

    render() {
        const { main } = this.props
        return (
            <div className="section" id="Statistic">
                <div className="container-fluid project-counter-two-area project-counter-two-area-bg section-space--inner--60">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="project-counter-two-wrapper">
                                <div className="row" hidden>
                                    <div className="col-md-3">
                                        <div className="project-counter-two-single text-center">
                                            <h3 className="project-counter-two-single__number counter">{main.PRO_CONSULTANS}</h3>
                                            <p className="project-counter-two-single__text">Pro Consultants</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="project-counter-two-single text-center">
                                            <h3 className="project-counter-two-single__number counter">{main.SATISFIED_CLIENTS}</h3>
                                            <p className="project-counter-two-single__text">Statisfied Clients</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="project-counter-two-single text-center">
                                            <h3 className="project-counter-two-single__number counter">{main.SUCCESSFULL_CASES}</h3>
                                            <p className="project-counter-two-single__text">Successfull Cases</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="project-counter-two-single text-center">
                                            <h3 className="project-counter-two-single__number counter">{main.YEARS_IN_BUSINESS}</h3>
                                            <p className="project-counter-two-single__text">Years In Business</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="project-counter-two-single text-center">
                                            <h3 className="project-counter-two-single__number counter">{main.jumlahCalonTenant}</h3>
                                            <p className="project-counter-two-single__text">Calon Tenant</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="project-counter-two-single text-center">
                                            <h3 className="project-counter-two-single__number counter">{main.jumlahTenant}</h3>
                                            <p className="project-counter-two-single__text">Tenant</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="project-counter-two-single text-center">
                                            <h3 className="project-counter-two-single__number counter">{main.jumlahTenantPenerimaDana}</h3>
                                            <p className="project-counter-two-single__text">Tenant Penerima Dana</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Statistic;