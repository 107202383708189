import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';
import { appendScript, removeElement } from '../utils/dom';
import { getClientProduct, getProductByClient, } from '../utils/request';
import { Link } from "react-router-dom";
import { scrollTo, formattingCurrency, } from "../utils/global";

export class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "ProductScript",
                src : "/js/component/Product.js",
                async : true,
            },
            remove : ".ProductScript", // string or array
            product : [],
        };
    }

    componentDidMount() {
        if(this.props.ClientId===undefined){
            this.requestAllProduct()
        }else{
            this.requestAllProductByClient()
        }
    }

    requestAllProduct(){
        const { attrs, remove } = this.state
		getClientProduct().then((res)=>{
            this.setState({ product : res })
            appendScript(attrs,remove)
		})
    }

    requestAllProductByClient(){
        const { attrs, remove } = this.state
        const { ClientId } = this.props
		getProductByClient({clientID:ClientId}).then((res)=>{
            this.setState({ product : res })
            appendScript(attrs,remove)
		})
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    render() {
        const { main } = this.props
        const { product } = this.state
        const title = (this.props.productTag!==undefined)? this.props.productTag.title
            : (main.language)? main.language.MENU_PRODUCT : "-"

        if(product.length<1 && this.props.allowRemoveOnEmpty!==undefined){ return("") }
        return (
            <div className="case-study-filter-area section-space--inner--120 grey-bg--style3" id="Product">
                <div className="container">
                    {(this.props.productTag!==undefined && this.props.productTag.removeHeader===true)?
                        <div></div>
                    :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-title-area text-center section-space--bottom--50">
                                    <h2 className="title">{ title }</h2>
                                    <p className="subtitle">Professional ethics govern the values of social responsibility that help us deliver our obligations within a coherent social framework.</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <ul className="project-filter-menu section-space--bottom--80">
                                <li className="active" data-filter="*">All</li>
                                <li data-filter=".energy">ENERGY</li>
                                <li data-filter=".environment">ENVIRONMENT</li>
                                <li data-filter=".industry">INDUSTRY</li>
                                <li data-filter=".manufacturing">MANUFACTURING</li>
                                <li data-filter=".oil-gas">OIL & GAS</li>
                            </ul> */}
                            <div className="project-filter-content">
                                <div className="row project-isotope">
                                    {product.map((p,i)=>(
                                        <div className="col-lg-4 col-md-6" key={i} style={{marginBottom:"20px", padding:"0px"}}>
                                            <div className="single-case-study-project" style={{height:"100%"}}>
                                                <Link to={"/product/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                    <div className="single-case-study-project__image">
                                                        <img src={p.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                                    </div>
                                                </Link>
                                                <div className="single-case-study-project__content">
                                                    <h3 className="title">
                                                        <Link to={"/product/"+p.SLUG} className="text-uppercase hover-underline" style={{color:"#ffc246"}} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                            {p.TITLE}
                                                        </Link>
                                                        <p style={{fontSize:"80%",marginBottom:"0px"}}>{p.NAME}</p>
                                                        <p style={{fontSize:"70%",marginBottom:"0px"}}>Rp {formattingCurrency(p.PRICE)}{p.UNIT}</p>
                                                    </h3>
                                                    { ReactHtmlParser(p.DESC_PRODUCTS.substr(0,300)) }
                                                    <br/>
                                                    <Link to={"/product/"+p.SLUG} className="see-more-link blog-readmore" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                        READ MORE <i className="ion-arrow-right-c"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Product;