import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';
import { appendScript, removeElement } from '../utils/dom';
import { Menu } from './Menu';
import { API_URL } from '../utils/constants';

export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "FooterScript",
                src : "/js/component/Footer.js",
                async : true,
            },
            remove : ".FooterScript", // string or array
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
        appendScript(attrs,remove)
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    render() {
        const { main } = this.props
        return (
            <footer id="footer">
                <div className="support-footer__area mt-5">
                    <div className="row no-gutters">
                        <div className="col-md-6">
                            <a href={"mailto:"+main.EMAIL} style={{width:"100%"}}>
                                <div className="support-footer__single bg-img" data-bg={API_URL + "uploads/static/message.png"}>
                                    Email: {main.EMAIL}
                                </div>
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a href={"tel:"+main.NO_TELP} className="text-decoration-none" target="_blank" rel="noreferrer" title="Call Us" style={{width:"100%"}} >
                                <div className="support-footer__single support-footer__single--dark bg-img" data-bg={API_URL + "uploads/static/support.png"}>
									<span className="custom-call-to-action-2 text-color-light text-2 custom-opacity-font">
										<span className="info text-4">
											<font color="black">Support: {main.NO_TELP}</font>
										</span>
									</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-area section-space--inner--top--120 dark-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="footer-content-wrapper">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-8">
                                            <div className="footer-intro-wrapper">
                                                <div className="footer-logo">
                                                    <a href="/home">
                                                        <img src={main.FULLPATH_LOGO_CLIENT} className="img-fluid" alt="" width="167px"/>
                                                    </a>
                                                </div>
                                                <div className="footer-desc">{ReactHtmlParser(main.ADDRESS)}</div>
                                                <div className="social-links">
                                                    <ul>
														<li><a href={main.FB} target="_blank" rel="noreferrer noopener" data-tippy="Facebook" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom" title="Facebook"><i className="ion-social-facebook"></i></a>
														</li>
														<li><a href={main.TW} target="_blank" rel="noreferrer" data-tippy="Twitter" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom" title="Twitter"><i className="ion-social-twitter"></i></a>
														</li>
														<li><a href={main.LI} target="_blank" rel="noreferrer" data-tippy="Linkedin" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom" title="Linkedin"><i className="ion-social-linkedin"></i></a>
														</li>
														<li><a href={main.IG} target="_blank" rel="noreferrer" data-tippy="Instagram" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom" title="Instagram"><i className="ion-social-instagram"></i></a>
														</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <Menu main={main} forFooter={true}></Menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="footer-copyright-wrapper">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="footer-copyright-left">
                                                2021 &copy; {main.NAMA_CLIENT} - Copyright All Rights Reserved
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="footer-copyright-right">
                                                <ul className="footer-copyright-right__navigation">
                                                    <li><a href="/home">Privacy</a></li>
                                                    <li><a href="/home">Terms of Use</a></li>
                                                    <li><a href="/home">Site Map</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-menu-overlay" id="mobile-menu-overlay">
                    <div className="mobile-menu-overlay__header">
                        <div className="container-fluid--cp-60">
                            <div className="row align-items-center">
                                <div className="col-md-4 col-sm-6 col-9">
                                    <div className="logo">
                                        <a href="/home">
                                            <img src={"assets/img/logo/logo-dark.png"} className="img-fluid" alt=""/>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6 col-3">
                                    <div className="mobile-menu-content">
                                        <a className="mobile-navigation-close-icon" id="mobile-menu-close-trigger" href="/footer" onClick={(d)=>{d.preventDefault();}}>
                                            <i className="ion-ios-close-empty"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-menu-overlay__body">
                        <nav className="offcanvas-navigation">
                            <Menu main={main}></Menu>
                        </nav>
                    </div>
                </div>
                <a href="/home" className="scroll-top" id="scroll-top">
                    <i className="ion-android-arrow-up"></i>
                </a>
            </footer>
        )
    }
}

export default Footer;