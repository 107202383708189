import React, { Component } from "react";
import { Link } from "react-router-dom";
import { scrollTo } from "../utils/global";
import { getPages } from '../utils/request';

export class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
			programs: [],
            eco: [],
            menuDefault: {
                MENU_HOME: "???",
                MENU_EVENT: "???",
                MENU_STATISTIC: "???",
                MENU_TOP10: "???",
                MENU_MENTOR: "???",
                MENU_UPCOMING: "???",
                MENU_PARTNER: "???",
                MENU_ABOUT_US: "???",
                MENU_STORY: "???",
                MENU_VISIMISI: "???",
                MENU_TEAM: "???",
                MENU_CONTACT: "???",
                MENU_GALLERY: "???",
                MENU_PRODUCT: "???",
                MENU_TENANT: "???",
                MENU_PROGRAM: "???",
                MENU_NEWS: "???",
                MENU_ACTIVITY: "???",
                MENU_ARTICLE: "???",
                MENU_DOWNLOAD: "???",
                MENU_ECOSYSTEM: "???"
            },
        }
    }

    componentDidMount(){
        getPages().then((pgs)=>{
            this.setState({ eco:pgs })
        })
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.update===true){
        this.componentDidMount()
      }
    }

    forFooter(){
        const { main } = this.props
        const { menuDefault } = this.state //programs, 
        const langs = (main.language===undefined)? menuDefault : main.language
        return [
            (<div className="col-lg-2 col-md-4" key="1">
                <div className="footer-widget">
                    <h4 className="footer-widget__title">
                        <Link to="/about" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_ABOUT_US }</Link>
                    </h4>
                    <ul className="footer-widget__navigation">
                        <li>
                            <Link to="/about#Story" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_STORY }
                            </Link>
                        </li>
                        <li>
                            <Link to="/about#VisionMission" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_VISIMISI }
                            </Link>
                        </li>
                        <li>
                            <Link to="/about#Team" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_TEAM }
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>),
            (<div className="col-lg-2 col-md-4" key="2">
                <div className="footer-widget">
                    <h4 className="footer-widget__title">
                        <Link to="/gallery" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_GALLERY }</Link>
                    </h4>
                    <ul className="footer-widget__navigation">
                        <li>
                            <Link to="/gallery#Product" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_PRODUCT }
                            </Link>
                        </li>
                        <li>
                            <Link to="/gallery#Tenant" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_TENANT }
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>),
            // (<div className="col-lg-2 col-md-4" key="3">
            //     <div className="footer-widget">
            //         <h4 className="footer-widget__title">
            //             <Link to="/program" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_PROGRAM }</Link>
            //         </h4>
            //         <ul className="footer-widget__navigation">
            //             {programs.map((p,i)=>(
            //                 <li key={i}>
            //                     <Link to={"/program/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
            //                         { p.TITLE }
            //                     </Link>
            //                 </li>
            //             ))}
            //         </ul>
            //     </div>
            // </div>),
            (<div className="col-lg-2 col-md-4" key="4">
                <div className="footer-widget">
                    <h4 className="footer-widget__title">
                        <Link to="/news" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_NEWS }</Link>
                    </h4>
                    <ul className="footer-widget__navigation">
                        <li>
                            <Link to="/news#Activity" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_ACTIVITY }
                            </Link>
                        </li>
                        <li>
                            <Link to="/news#Article" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_ARTICLE }
                            </Link>
                        </li>
                        <li>
                            <Link to="/news#Download" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_DOWNLOAD }
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>),
        ]
    }
    
    render() {
        if(this.props.forFooter===true){ return this.forFooter() }

        const { main } = this.props
        const { eco, menuDefault } = this.state //programs, 
        const langs = (main.language===undefined)? menuDefault : main.language
        return (
            <ul>
                <li className="has-children has-children--multilevel-submenu">
                    <Link to="/home" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_HOME }</Link>
                    <ul className="submenu">
                        <li>
                            <Link to="/home#NewestEvent" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_EVENT }
                            </Link>
                        </li>
                        <li>
                            <Link to="/home#Ecosystem" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_ECOSYSTEM }
                            </Link>
                        </li>
                        <li>
                            <Link to="/home#Top10" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_TOP10 }
                            </Link>
                        </li>
                        <li>
                            <Link to="/home#Mentors" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_MENTOR }
                            </Link>
                        </li>
                        <li>
                            <Link to="/home#UpcomingEvent" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_UPCOMING }
                            </Link>
                        </li>
                        <li>
                            <Link to="/home#Partners" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_PARTNER }
                            </Link>
                        </li>
                    </ul>
                </li>
                <li className="has-children has-children--multilevel-submenu">
                    <Link to="/ecosystem" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_ECOSYSTEM }</Link>
                    <ul className="submenu">
                        {eco.map((p,i)=>(
                            <li key={i}>
                                <Link to={"/ecosystem/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                    { p.TITLE }
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="has-children has-children--multilevel-submenu">
                    <Link to="/gallery" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_GALLERY }</Link>
                    <ul className="submenu">
                        <li>
                            <Link to="/gallery#Product" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_PRODUCT }
                            </Link>
                        </li>
                        <li>
                            <Link to="/gallery#Tenant" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_TENANT }
                            </Link>
                        </li>
                    </ul>
                </li>
                <li className="has-children has-children--multilevel-submenu">
                    <Link to="/about" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_ABOUT_US }</Link>
                    <ul className="submenu">
                        <li>
                            <Link to="/about#Story" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_STORY }
                            </Link>
                        </li>
                        <li>
                            <Link to="/about#VisionMission" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_VISIMISI }
                            </Link>
                        </li>
                        <li>
                            <Link to="/about#Team" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_TEAM }
                            </Link>
                        </li>
                    </ul>
                </li>
                {/* <li className="has-children has-children--multilevel-submenu">
                    <Link to="/program" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_PROGRAM }</Link>
                    <ul className="submenu">
                        {programs.map((p,i)=>(
                            <li key={i}>
                                <Link to={"/program/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                    { p.TITLE }
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li> */}
                <li className="has-children has-children--multilevel-submenu">
                    <Link to="/news" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_NEWS }</Link>
                    <ul className="submenu">
                        <li>
                            <Link to="/news#Activity" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_ACTIVITY }
                            </Link>
                        </li>
                        <li>
                            <Link to="/news#Article" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_ARTICLE }
                            </Link>
                        </li>
                        <li>
                            <Link to="/news#Download" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                { langs.MENU_DOWNLOAD }
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/contact" className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{ langs.MENU_CONTACT }</Link>
                </li>
            </ul>
          );
    }
}

export default Menu;
