import React, { Component } from 'react'
import { Product, Tenant, Testimonial } from "../components";

export class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bc : false,
        };
    }

    componentDidMount() {
        this.setBreadcrumb()
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.update===true){
            
        }

        this.setBreadcrumb()
    }

    setBreadcrumb(){
        const { bc } = this.state
        const { setBreadcrumb, main, } = this.props

        if(bc===false){
            var title = (main.language)? main.language.MENU_GALLERY : "-"
            setBreadcrumb(title, [{name:title, link:"/gallery"}])
            this.setState({ bc:true })
        }
    }

    render() {
        return (
            <main>
                <div role="main" className="main">
                    <Product {...this.props} />
                    <Tenant {...this.props} />
                    <Testimonial {...this.props} />
                </div>
            </main>
        )
    }
}

export default Gallery
