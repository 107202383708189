import React, { Component } from "react";
import { NewestEvent, Statistic, Top10, MentorsSlider, UpcomingEvent, Partners, EcosystemSlider, } from "../components"

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bc : false,
    };
  }

  componentDidMount() {
    this.setBreadcrumb()
  }
    
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.update===true){
    }

    this.setBreadcrumb()
  }

  setBreadcrumb(){
      const { bc } = this.state
      const { setBreadcrumb } = this.props
      
      if(bc===false){
          setBreadcrumb("Home", [])
          this.setState({ bc:true })
      }
  }

  render() {
    return (
      <main>
        <div role="main" className="main">
            <NewestEvent {...this.props} />
            <Statistic {...this.props} />
            <EcosystemSlider {...this.props} />
            <Top10 {...this.props} />
            <MentorsSlider {...this.props} />
            <UpcomingEvent {...this.props} />
            <Partners {...this.props} />
        </div>
      </main>
    );
  }
}

export default Home;

