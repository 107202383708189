import React, { Component } from "react";
import { getPortfolioClient } from '../utils/request';
import { Link } from 'react-router-dom'
import { scrollTo } from "../utils/global";
import { paginationDom, } from "../utils/dom";
import $ from 'jquery'
import { withRouter } from "react-router";
import { dmy } from "../utils/date"

export class PortofolioGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acts : [],
            total : 0,
            limit : 9,
            currentPage : 1,
            category : 2,
            btnPages : [],
            update : false,
        };

		this.goTo = this.goTo.bind(this)
    }

    componentDidMount() {
        let btnPages = []
        let total = 0
        const { limit, currentPage, category, } = this.state
        const { ClientId } = this.props
        const params = {clientID:ClientId, category:category, limit:limit, pagin:currentPage}
		getPortfolioClient(params).then((res)=>{
            if(res.length>0){
                total = res.length
                for(var i=1; i<=Math.ceil(total/limit); i++){
                    btnPages.push(i)
                }
            }
            this.setState({
                acts:res,
                total:total,
                btnPages:btnPages,
            })
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { update, } = this.state
		if(prevProps.update===true || update===true){
            this.componentDidMount()
            this.setState({ update:false })
        }
    }

    goTo(page){
        const { update } = this.state
        this.setState({ currentPage:page, update:true })
        setTimeout(function(){
            if(update===false)
                scrollTo({target:$("#Activity")[0]})
        },500)
    }

    render() {
        const { location, main, } = this.props
        const { acts, currentPage, btnPages, } = this.state
        const title = (this.props.portofolioGridTag!==undefined)? this.props.portofolioGridTag.title
            : (main.language)? main.language.PORTOFOLIO : "-"

        if(acts.length<1){ return("") }
        return (
            <div className="page-content-wrapper section-space--inner--120" id="PortofolioGrid" href={location.pathname+"#PortofolioGrid"}>
                <div className="container">
                    {(this.props.portofolioGridTag!==undefined && this.props.portofolioGridTag.removeHeader===true)?
                        <div></div>
                    :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-title-area text-center section-space--bottom--50">
                                    <h2 className="title">{ title }</h2>
                                    <p className="subtitle">Professional ethics govern the values of social responsibility that help us deliver our obligations within a coherent social framework.</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        {(acts.length<=6 || window.navigator.userAgentData.mobile===true)?
                            (<div className="col-lg-8 text-right"></div>)
                        :
                            (<div className="col-lg-8 text-right">
                                {paginationDom(currentPage,btnPages,this.goTo)}
                            </div>)
                        }
                    </div>
                    <div className="row section-space--inner--bottom--50">
                        <div className="col-lg-12">
                            <div className="blog-grid-wrapper blog-grid-wrapper--style6">
                                <div className="row">
                                    {acts.map((a,i)=>(
                                        <div className="col-lg-4 col-md-6" key={i}>
                                            <div className="blog-post-slider__single-slide blog-post-slider__single-slide--extra-space blog-post-slider__single-slide--grid-view blog-post-slider__single-slide--grid-view--style2">
                                                <div className="blog-post-slider__image">
                                                    <Link to={"/article/"+a.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }} style={{width:"100%",height:"100%"}}>
                                                        <img src={a.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                                    </Link>
                                                </div>
                                                <div className="blog-post-slider__content blog-post-slider__content--style2">
                                                    <div className="post-meta">
                                                        <p className="post-date">{dmy(a.DATE_PORTOFOLIO)}</p>
                                                        <div className="post-category">
                                                            <p>{a.SUB_TITLE}</p>
                                                        </div>
                                                    </div>
                                                    <h3 className="post-title">
                                                        <Link to={"/article/"+a.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>{a.TITLE}</Link>
                                                    </h3>
                                                    <p className="post-excerpt">{a.DESC_PORTOFOLIO}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            {paginationDom(currentPage,btnPages,this.goTo)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(PortofolioGrid);