import React, { Component } from 'react'
import { withRouter } from "react-router";
import { appendScript, removeElement } from '../utils/dom';
import ReactHtmlParser from 'react-html-parser';
import { getDetailService, getClientLanding, } from '../utils/request';
import { Share } from '../components';
import { Redirect, } from "react-router-dom";

export class ProgramDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "ProgramDetailScript",
                src : "/js/component/ProgramDetail.js",
                async : true,
            },
            remove : ".ProgramDetailScript", // string or array
            program : {},
			update: false,
            bc : false,
            testi : [],
        };
    }

    componentDidMount() {
        const { match } = this.props
        const { attrs, remove } = this.state
        
		getDetailService({slug:match.params.id}).then((res)=>{
            if(res===undefined){ return }
            getClientLanding().then((clt)=>{
                this.setState({ testi : clt, program : res, bc : false })
                this.setBreadcrumb()
                appendScript(attrs,remove)
            })
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match } = this.props

        if(prevProps.update===true || prevProps.match.params.id!==match.params.id){
            this.componentDidMount()
        }
        
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }

    setBreadcrumb(){
        const { main } = this.props
        const { bc, program } = this.state
        const { setBreadcrumb, match } = this.props

        if(bc===true){ return }

        var title = (main.language)? main.language.MENU_PROGRAM : "-"
        var arr = [{name:title, link:"/program"}]

        if(match.params.hasOwnProperty("id")){
            var id = match.params.id
            var name = program.TITLE
            
            arr.push({name:name,link:"/program/"+id})
        }

        setBreadcrumb(title, arr)
        this.setState({ bc:true })
    }

    render() {
        const { program } = this.state
        if(program===undefined){ return <Redirect push to="/404" /> }
        return (
            <main>
                <div role="main" className="main">
                    <div className="page-content-wrapper section-space--inner--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="common-page-content">
                                        <div className="common-page-text-wrapper section-space--bottom--50">
                                            <h2 className="common-page-title">{ program.TITLE }</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="service-details-page-content">

                                        <div className="service-details-gallery-slider-wrapper" style={{float:"left",marginRight:"10px",width:"40%"}}>
                                            <img src={program.FULL_PATH_IMAGE} className="img-fluid" alt="" style={{width:"100%",height:"auto"}}/>
                                        </div>

                                        <div className="service-details-text-wrapper section-space--bottom--60">
                                            {/* <h2 className="title">Industry Solutions</h2> */}
                                            { ReactHtmlParser(program.DESC_SERVICE) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="float-right">
                                        <Share/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(ProgramDetail)
