import React, { Component } from "react";
import { appendScript, removeElement } from '../utils/dom';
import { getEvents } from '../utils/request';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom'
import { scrollTo } from "../utils/global";
import { dmy } from "../utils/date"

export class UpcomingEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "UpcomingEventScript",
                src : "/js/component/UpcomingEvent.js",
                async : true,
            },
            remove : ".UpcomingEventScript", // string or array
            news : [],
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
		getEvents({limit:4}).then((res)=>{
			this.setState({ news : res.events });
            window.g.NewsLength = res.length
            appendScript(attrs,remove)
		})
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    render() {
        const { main } = this.props
        const title = (main.language)? main.language.MENU_UPCOMING : "-"
        let { news } = this.state
        if(Array.isArray(news) && news.length>0){
            var slice = (news.length>=4)?4:news.length
            if(slice>0){
                news = news.slice(0,slice)
            }
        }
        return (
            <div className="blog-newsletter-area section-space--inner--120" id="UpcomingEvent">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="section-title-area section-title-area--middle section-space--bottom--80">
                                <h2 className="title section-space--bottom--30">{ title }</h2>
                                <p className="section-title-content text-center">It is imperative that everyone follows the policies and guidelines to ensure their own safety and the safety of others around them.</p>
                            </div>
                        </div>
        
                        <div className="col-lg-12">
                            <div className="blog-newspaper-content-wrapper">
                                <div className="row">
                                    <div className="col-lg-3 col-md-12">
                                        <div className="blog-title-wrapper">
                                            {news.map((n,i)=>(
                                                <div key={"news-side-"+i} className="single-blog-title">
                                                    <h3 className="post-title">
                                                        <Link to={"/event/"+n.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>{n.NAME}</Link>
                                                    </h3>
                                                    <p className="post-date">{ dmy(n.DATE_EVENT) }</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-6">
                                        <div className="blog-post-slider__area">
                                            <div className="blog-post-slider__container-area">
                                                <div className="swiper-container blog-post-slider__container">
                                                    <div className="swiper-wrapper blog-post-slider__wrapper">
                                                        {news.map((n,i)=>(
                                                            <div key={"news-center"+i} className="swiper-slide blog-post-slider__single-slide">
                                                                <div className="blog-post-slider__image section-space--bottom--30">
                                                                    <Link to={"/event/"+n.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                                        <img src={n.FULL_PATH_IMAGE} className="img-fluid" alt="" style={{width:"470px", height:"298px"}} />
                                                                    </Link>
                                                                </div>
                                                                <div className="blog-post-slider__content">
                                                                    <p className="post-date">{ dmy(n.DATE_EVENT) }</p>
                                                                    <h3 className="post-title">
                                                                        <Link to={"/event/"+n.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>{n.NAME}</Link>
                                                                    </h3>
                                                                    <div className="post-excerpt">{ ReactHtmlParser(n.DESC_EVENT.substr(0,300)) }</div>
                                                                    <Link to={"/event/"+n.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }} className="see-more-link blog-readmore">
                                                                        READ MORE <i className="ion-arrow-right-c"></i>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="newsletter-box-wrapper bg-img" data-bg={"assets/img/icons/message.png"}>
                                            <h3 className="newsletter-title">Subscribe for our latest topics</h3>
                                            <div className="newsletter-form-wrapper">
                                                <form>
                                                    <input type="text" placeholder="Name" id="subcribe_name"/>
                                                    <input type="email" placeholder="Email" id="subcribe_email"/>
                                                    <button type="button">SUBSCRIBE</button>
                                                </form>
                                            </div>
                                            <h3 className="call-text">
                                                <Link to={"tel:"+main.NO_TELP} title="Call Us" className="text-decoration-none" target="_blank" rel="noreferrer" onClick={(el)=>{ scrollTo(el) }}>{main.NO_TELP}</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UpcomingEvent;