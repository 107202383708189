import React, { Component } from "react";
import { appendScript, removeElement } from '../utils/dom';
import { getPages } from '../utils/request';
import { Link } from 'react-router-dom'
import { scrollTo } from "../utils/global";


export class EcosystemSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "EcosystemSliderScript",
                src : "/js/component/EcosystemSlider.js",
                async : true,
            },
            remove : ".EcosystemSliderScript", // string or array
            ecosystem : [],
			update: false
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
		getPages().then((res)=>{
            this.setState({ ecosystem : res })
            window.g.EcosystemSliderLength = res.length
            appendScript(attrs,remove)
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }

    render() {
        const { main } = this.props
        const { ecosystem } = this.state
        const title = (this.props.ecosystemTag!==undefined)? this.props.ecosystemTag.title
            : (main.language)? main.language.MENU_ECOSYSTEM : "-"
        return (
            <div className="ecosystem-member-slider-area section-space--inner--top--120" id="Ecosystem">
                <div className="container">
                    {(this.props.ecosystemTag!==undefined && this.props.ecosystemTag.removeHeader===true)?
                        <div></div>
                    :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-title-area text-center section-space--bottom--50">
                                    <h2 className="title">{ title }</h2>
                                    <p className="subtitle">Professional ethics govern the values of social responsibility that help us deliver our obligations within a coherent social framework.</p>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ecosystem-slider">
                                <div className="swiper-container ecosystem-slider__container">
                                    <div className="swiper-wrapper ecosystem-slider__wrapper">
                                        {ecosystem.map((p,i) => (
                                            <div key={"mentors-"+i} className="swiper-slide">
                                                <div className="ecosystem-slider__single">
                                                    <div className="image">
                                                        <Link to={"/ecosystem/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                            <img src={p.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                                        </Link>
                                                    </div>
                                                    <div className="content">
                                                        <div className="identity-wrapper has-border-left single-case-study-project__content">
                                                            <h3 className="name title">
                                                                <Link to={"/ecosystem/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                                    {p.TITLE}
                                                                </Link>
                                                            </h3>
                                                            <span className="designation">{p.SUB_TITLE}</span>
                                                        </div>
                                                        <div className="social-links social-links--white-topbar d-inline-block">
                                                            <ul>
                                                                <li>
																	<a href="//facebook.com">
																		<i className="ion-social-facebook"></i>
																	</a>
																</li>
                                                                <li>
																	<a href="//twitter.com">
																		<i className="ion-social-twitter"></i>
																	</a>
																</li>
                                                                <li>
																	<a href="//linkedin.com">
																		<i className="ion-social-linkedin"></i>
																	</a>
																</li>
                                                                <li>
																	<a href="//skype.com">
																		<i className="ion-social-skype"></i>
																	</a>
																</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="ht-swiper-button-prev ht-swiper-button-prev-14 ht-swiper-button-nav">
									<i className="ion-chevron-left"></i>
								</div>
                                <div className="ht-swiper-button-next ht-swiper-button-next-14 ht-swiper-button-nav">
									<i className="ion-chevron-right"></i>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EcosystemSlider;