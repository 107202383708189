import React, { Component } from "react";
import { getServiceClient } from '../utils/request';
import ReactHtmlParser from 'react-html-parser';


export class ServiceAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            service : [],
			update: false
        };
    }

    componentDidMount() {
        const { ClientId } = this.props
		getServiceClient({clientID:ClientId}).then((res)=>{
            this.setState({ service : res })
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true || prevProps.clientId!==this.props.clientId){
            this.componentDidMount()
        }
    }

    render() {
        let { service } = this.state
        const { main } = this.props
        const title = (this.props.ServiceAccordion!==undefined)? this.props.ServiceAccordion.title
            : (main.language)? main.language.SERVICE : "-"

        if((Array.isArray(service) && service.length<1) || service===undefined || Object.keys(service).length<1){ return("") }

        service = (Array.isArray(service))? service : [service]
        return (
            <div className="section-space--inner--top--40" id="Service">
                <div className="container">
                    {(this.props.ServiceAccordion!==undefined && this.props.ServiceAccordion.removeHeader===true)?
                        <div></div>
                    :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-title-area text-center section-space--bottom--50">
                                    <h2 className="title">{ title }</h2>
                                    <p className="subtitle">Professional ethics govern the values of social responsibility that help us deliver our obligations within a coherent social framework.</p>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="accordion" id="ServiceAccordion">
                                {service.map((d,i)=>{
                                    d = service[i]
                                    var classes = (i===0)? "collapse show mt-1 p-3" : "collapse mt-1 p-3"
                                    return(
                                        <div className="card no-border mt-1" key={"accors"+i}>
                                            <div className="card-header project-counter-two-area-bg cursor-pointer border-radius-50" id={"heading"+i} data-toggle="collapse" data-target={"#collapse"+i} aria-expanded="true" aria-controls={"collapse"+i}>
                                                <h2 className="mb-0">
                                                    <button className="btn text-left color-white accordion-arrow no-border" type="button" style={{width:"100%"}}>
                                                        {d.TITLE}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id={"collapse"+i} className={classes} aria-labelledby={"heading"+i} data-parent="#ServiceAccordion">
                                                <div className="card-body with-border">
                                                    {ReactHtmlParser(d.DESCRIPTION)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceAccordion;