import React, { Component } from 'react'
import { withRouter } from "react-router";
import { appendScript, removeElement } from '../utils/dom';
import ReactHtmlParser from 'react-html-parser';
import { getDetailPages, } from '../utils/request';
import { Share, PageDetailAccordion, } from '../components';
import { Redirect, } from "react-router-dom";

export class EcosystemDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "EcosystemDetailScript",
                src : "/js/component/EcosystemDetail.js",
                async : true,
            },
            remove : ".EcosystemDetailScript", // string or array
            eco : {},
			update: false,
            bc : false,
            details : [],
        };
    }

    componentDidMount() {
        const { match } = this.props
        const { attrs, remove } = this.state
        
		getDetailPages({slug:match.params.id}).then((resEco)=>{
            this.setState({ eco:resEco, bc:false })
            this.setBreadcrumb()
            appendScript(attrs,remove)
        })
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match } = this.props

        if(prevProps.update===true || prevProps.match.params.id!==match.params.id){
            this.componentDidMount()
        }
        
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }

    setBreadcrumb(){
        const { bc, eco } = this.state
        const { setBreadcrumb, match, main } = this.props

        if(bc===true){ return }
        if(eco===undefined){ return }

        var title = (main.language)? main.language.MENU_ECOSYSTEM : "-"
        var arr = [{name:title, link:"/ecosystem"}]

        if(match.params.hasOwnProperty("id")){
            var id = match.params.id
            var name = eco.TITLE
            
            arr.push({name:name,link:"/ecosystem/"+id})
        }

        setBreadcrumb(title, arr)
        this.setState({ bc:true })
    }

    render() {
        const { eco, } = this.state
        if(eco===undefined){ return <Redirect push to="/404" /> }
        return (
            <main>
                <div role="main" className="main">
                    <div className="page-content-wrapper section-space--inner--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div style={{float:"left",marginRight:"10px",width:"40%"}}>
                                        <img src={eco.FULL_PATH_IMAGE} className="img-fluid" alt="" style={{width:"100%",height:"auto"}}/>
                                    </div>
                                    <h3 className="title">{eco.TITLE}</h3>
                                    <h4 className="title">{eco.MINI_TITLE}</h4>
                                    <h4 className="title">{eco.SUB_TITLE}</h4>
                                    { ReactHtmlParser(eco.DESC_PAGES) }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <PageDetailAccordion pageId={eco.ID} {...this.props}/>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12">
                                    <div className="float-right">
                                        <Share/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(EcosystemDetail)
