import React, { Component } from 'react'
import { withRouter } from "react-router";
import { removeElement } from '../utils/dom'; //appendScript, 
import ReactHtmlParser from 'react-html-parser';
import { getService } from '../utils/request';
import { Link } from "react-router-dom";
import { scrollTo } from "../utils/global";
import { dmy } from "../utils/date"

export class Program extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "ProgramScript",
                src : "/js/component/Program.js",
                async : true,
            },
            remove : ".ProgramScript", // string or array
            programs : [],
			update: false,
            bc : false,
        };
    }

    componentDidMount() {
		getService().then((res)=>{
            this.setState({ programs : res })
		})
        
        this.setBreadcrumb()
        // const { attrs, remove } = this.state
        // appendScript(attrs,remove)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.update===true){
            getService().then((res)=>{
                this.setState({ programs : res })
            })
        }
        
        this.setBreadcrumb()
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }

    setBreadcrumb(){
        const { main } = this.props
        const { bc } = this.state
        const { setBreadcrumb } = this.props

        if(bc===false){
            var title = (main.language)? main.language.MENU_PROGRAM : "-"
            var arr = [{name:title, link:"/program"}]

            setBreadcrumb(title, arr)
            this.setState({ bc:true })
        }
    }

    render() {
        const { programs } = this.state
        return (
            <main>
                <div role="main" className="main">
                    <div className="page-content-wrapper section-space--inner--120" id="Product">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="blog-grid-wrapper">
                                        <div className="row">
                                            {programs.map((p,i)=>(
                                                <div className="col-lg-4 col-md-6" key={i}>
                                                    <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                                                        <div className="blog-post-slider__image section-space--bottom--30">
                                                            <Link to={"/program/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                                <img src={p.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                                            </Link>
                                                        </div>
                                                        <div className="blog-post-slider__content">
                                                            <p className="post-date">{dmy(p.DATE_ADDED)}</p>
                                                            <h3 className="post-title">
                                                                <Link to={"/program/"+p.SLUG} className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{p.TITLE}</Link>
                                                            </h3>
                                                            { ReactHtmlParser(p.DESC_SERVICE.substr(0,300)) }
                                                            <Link to={"/program/"+p.SLUG} className="see-more-link blog-readmore" target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                                <span>READ MORE <i className="ion-arrow-right-c"></i></span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(Program)
