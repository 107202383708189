import React, { Component } from "react"
import { EmailShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton, } from "react-share";
import { EmailIcon, FacebookIcon, TelegramIcon, TwitterIcon, WhatsappIcon, } from "react-share";

export class Share extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount(){
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.update===true){
        this.componentDidMount()
      }
    }
    
    render() {
        return (
            <div>
                <EmailShareButton url={window.location.href}>
                    <EmailIcon size={32} round={true}></EmailIcon>
                </EmailShareButton>
                &nbsp;
                <FacebookShareButton url={window.location.href}>
                    <FacebookIcon size={32} round={true}></FacebookIcon>
                </FacebookShareButton>
                &nbsp;
                <TelegramShareButton url={window.location.href}>
                    <TelegramIcon size={32} round={true}></TelegramIcon>
                </TelegramShareButton>
                &nbsp;
                <TwitterShareButton url={window.location.href}>
                    <TwitterIcon size={32} round={true}></TwitterIcon>
                </TwitterShareButton>
                &nbsp;
                <WhatsappShareButton url={window.location.href}>
                    <WhatsappIcon size={32} round={true}></WhatsappIcon>
                </WhatsappShareButton>
            </div>
        )
    }
}

export default Share;
