import React, { Component } from "react";
import { appendScript, removeElement } from '../utils/dom';
import { getClientLanding } from '../utils/request';
import { Link } from "react-router-dom";
import { scrollTo } from "../utils/global";

export class Partners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "PartnersScript",
                src : "/js/component/Partners.js",
                async : true,
            },
            remove : ".PartnersScript", // string or array
            partners : [],
			width : "100%",
			height : "90px",
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
        getClientLanding().then((res)=>{
            this.setState({ partners : res })
            appendScript(attrs,remove)
        })
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }
    
    render() {
        const { partners } = this.state
        const imgAttrs = {
            className : "img-fluid",
            style : {
                maxHeight : "100px"
            }
        }
        const imgParent = {
            style : {
                padding : "10px",
            }
        }
        return (
            <div id="Partners">
                <div className="testimonial-brand-slider-area grey-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="brand-logo-slider__container-area">
                                    <div className="swiper-container brand-logo-slider__container">
                                        <div className="swiper-wrapper brand-logo-slider__wrapper">
                                            {partners.map((t,i)=>(
                                                <div className="swiper-slide brand-logo-slider__single" title={t.NAME} {...imgParent} key={i}>
                                                    <div className="image">
                                                        <Link to={"/tenant/"+t.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                            <img src={t.FULL_PATH_IMAGE_THUM} alt={t.NAME} {...imgAttrs}/>
                                                        </Link>
                                                    </div>
                                                    <div className="image-hover">
                                                        <Link to={"/tenant/"+t.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                            <img src={t.FULL_PATH_IMAGE} alt={t.NAME} {...imgAttrs}/>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Partners;