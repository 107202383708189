import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./assets/m/css/vendor/bootstrap.min.css"
import "./assets/m/css/vendor/font-awesome.min.css"
import "./assets/m/css/vendor/ionicons.min.css"
import "./assets/m/css/vendor/flaticon.min.css"
import "./assets/m/css/vendor/icomoon.min.css"
import "./assets/m/css/vendor/tractor-icon.min.css"
import "./assets/m/css/plugins/swiper.min.css"
import "./assets/m/css/plugins/animate.min.css"
import "./assets/m/css/plugins/lightgallery.min.css"

import "./assets/m/css/style.css"

import "./assets/m/revolution/css/settings.css"
import "./assets/m/revolution/css/navigation.css"
import "./assets/m/revolution/custom-setting.css"

import { global } from './utils/global'
global()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
