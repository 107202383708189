import React, { Component } from "react";
import { getClientProductLanding } from '../utils/request';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom'
import { scrollTo, formattingCurrency } from "../utils/global";
import { appendScript, removeElement } from '../utils/dom';

export class Top10 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "Top10Script",
                src : "/js/component/Top10.js",
                async : true,
            },
            remove : ".Top10Script", // string or array
            person : [],
			width : "100%",
			height : "100%",
			update: false
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
		getClientProductLanding().then((res)=>{
			this.setState({ person : res });
            appendScript(attrs,remove)
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }

    domImage(p,i){
        const { width, height } = this.state
        return (
            <div className="project-item" key={"1"+i}>
                <div className="project" style={{width: width, height: height,}}>
                    <Link to={"/product/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }} className="top10-price top10-height" style={{width: width, height: height, backgroundPosition: 'top center', backgroundRepeat: 'no-repeat', backgroundImage:"url("+p.FULL_PATH_IMAGE+")", backgroundSize: "100% 100%",}}>
                        <p>Rp {formattingCurrency(p.PRICE)}{p.UNIT}</p>
                    </Link>
                </div>
            </div>)
    }

    domName(p,i){
        const { width, height } = this.state
        return (
            <div className="project-item" key={"2"+i}>
                <div className="project" style={{width: width, height: height, backgroundColor: '#ffc246', display: 'block', position: 'relative', padding: '30px'}}>
                    <h3>
                        <Link to={"/product/"+p.SLUG} target="_self" className="hover-underline" onClick={(el)=>{ scrollTo(el) }} style={{fontSize:"150%"}}>
                            {p.TITLE}
                        </Link>
                        <p className="color-whitesmoke">
                        <Link to={"/product/"+p.SLUG} target="_self" className="hover-underline" onClick={(el)=>{ scrollTo(el) }}>
                            {p.NAME}
                        </Link>
                        </p>
                        <ul className="topbar-menu">
                            <li>
                                <a href={"//"+p.LINK_YT} target="_blank" rel="noreferrer" data-tippy="Youtube" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__black" data-tippy-placement="bottom" title="Youtube"><i className="ion-social-youtube"></i></a>
                            </li>
                            <li>
                                <a href={"//"+p.LINK_IG} target="_blank" rel="noreferrer" data-tippy="Instagram" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__black" data-tippy-placement="bottom" title="Instagram"><i className="ion-social-instagram"></i></a>
                            </li>
                            <li>
                                <a href={"tel:"+p.PHONE_NO} target="_blank" rel="noreferrer" data-tippy="Phone" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__black" data-tippy-placement="bottom" title="Phone"><i className="ion-ios-telephone"></i></a>
                            </li>
                            <li style={{fontSize:"120%"}}>
                                <a href={"mailto:"+p.EMAIL} target="_blank" rel="noreferrer" data-tippy="Email" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__black" data-tippy-placement="bottom" title="Email"><i className="ion-ios-email"></i></a>
                            </li>
                        </ul>
                    </h3>
                </div>
            </div>)
    }

    domDesc(p,i){
        const { width, height } = this.state
        return (
            <div className="project-item" key={"3"+i}>
                <div className="project" style={{width: width, height: height,}}>
                    <Link to={"/product/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }} className="image top10-height" style={{width: width, height: height, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundImage:"url("+p.FULL_PATH_IMAGE_CLIENT+")", backgroundSize: "100% 100%", padding: '15px',}}>
                    </Link>
                    <div className="content" style={{padding:"0px"}}>
                        <h4 className="title">
                            <Link to={"/product/"+p.SLUG} target="_self" className="top10-height" onClick={(el)=>{ scrollTo(el) }} style={{width: width, height: height, overflow: 'hidden', padding:"20px"}}>
                                {ReactHtmlParser(p.DESC_PRODUCTS)}
                            </Link>
                        </h4>
                    </div>
                </div>
            </div>)
    }

    render() {
        const { main } = this.props
        const { person, } = this.state
        const title = (this.props.teamTag!==undefined)? this.props.teamTag.title
            : (main.language)? main.language.MENU_TOP10 : "-"
        const id = (this.props.teamTag!==undefined)? this.props.teamTag.id
            : "Top10"
        const styles = `
            #`+id+` .topbar-menu li a {
                color: white;
            }
            #`+id+` .topbar-menu li a:hover {
                color: #222;
            }`
        return (
            <div className="project-grid-area section-space--inner--top--120 top10-container" id={id}>
                <style>{styles}</style>
                {(this.props.teamTag!==undefined && this.props.teamTag.removeHeader===true)?
                    <div></div>
                :
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center section-space--bottom--80">
                                    <h2 className="title title--style5">{ title }</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {person.map((p,i)=>(
                    <div key={"top-10-"+i}>
                        <div className="project-grid-5-column">
                            <div className="project-item">
                                <div className="project"></div>
                            </div>
                            {(i%2===0) ?
                                [this.domImage(p,i),this.domName(p,i),this.domDesc(p,i)]
                            : 
                                [this.domName(p,i),this.domDesc(p,i),this.domImage(p,i)]
                            }
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default Top10;