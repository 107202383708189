// import './App.css';
import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect, } from "react-router-dom";
import { Home, P404, About, Gallery, News, Program, ProgramDetail, TenantPage,
	TenantDetail, ProductPage, ProductDetail, NewsDetail, Person, PersonDetail,
	EventPage, Ecosystem, EventDetail, EcosystemDetail, Contact, } from './pages'
import { Footer, Header, RouteChangeTracker }  from './components';
import ReactGa from "react-ga"
import { setLang } from './utils/lang';
import { getMain } from './utils/request';

ReactGa.initialize('UA-197052180-1')
export default class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			services: [],
			cases: [],
			main: {},
			pages: [],
			update: false,
			updateBreadcrumb: false,
			breadcrumb: {title:"Title", breadcrumb:[{name:"Title",link:"/title"}]},
		};

		this.setLang = this.setLang.bind(this)
		this.setBreadcrumb = this.setBreadcrumb.bind(this)
	}

	componentDidMount(){
		getMain().then((res)=>{
			this.setState({ main : res });
		})
	}
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.updateBreadcrumb===true){
			this.setState({ updateBreadcrumb : false })
		}else if(this.state.update===true){
			getMain().then((res)=>{
				this.setState({ main : res });
			})

            this.setState({ update : false })
		}
    }

    setLang(lang){
		setLang(lang)
		this.setState({ update : true })
	}

	setBreadcrumb(t,bc){
		this.setState({ breadcrumb:{title:t, breadcrumb:bc} })
		this.setState({ updateBreadcrumb : true })
	}

	render() {
		const params = {
			bc : false,
			main : this.state.main,
			update : this.state.update,
			updateBreadcrumb : this.state.updateBreadcrumb,
			setBreadcrumb : this.setBreadcrumb,
			breadcrumb : this.state.breadcrumb,
		}
		if(Object.keys(this.state.main).length===0){ return("") }
		return (
			<BrowserRouter>
				<RouteChangeTracker />
				<Header setLang={this.setLang} {...params} />
				<Switch>
					<Redirect exact from="/" to="/home" />
					<Route path="/home" exact> <Home {...params}/> </Route>
					<Route path="/person" exact> <Person {...params}/> </Route>
					<Route path="/person/:id" exact> <PersonDetail {...params}/> </Route>
					<Route path="/event" exact> <EventPage {...params}/> </Route>
					<Route path="/event/:id" exact> <EventDetail {...params}/> </Route>

					<Route path="/about" exact> <About {...params}/> </Route>

					<Route path="/gallery" exact> <Gallery {...params}/> </Route>
					<Route path="/tenant" exact> <TenantPage {...params}/> </Route>
					<Route path="/tenant/:id" exact> <TenantDetail {...params}/> </Route>
					<Route path="/product" exact> <ProductPage {...params}/> </Route>
					<Route path="/product/:id" exact> <ProductDetail {...params}/> </Route>

					<Route path="/news" exact> <News {...params}/> </Route>
					<Route path="/news/:id" exact> <NewsDetail {...params}/> </Route>
					<Route path="/article/:id" exact> <NewsDetail {...params} title={["Article","Artikel"]}/> </Route>
					<Route path="/activity/:id" exact> <NewsDetail {...params} title={["Activity","Aktivitas"]}/> </Route>

					<Route path="/program" exact> <Program {...params}/> </Route>
					<Route path="/program/:id" exact> <ProgramDetail {...params}/> </Route>

					<Route path="/ecosystem" exact> <Ecosystem {...params}/> </Route>
					<Route path="/ecosystem/:id" exact> <EcosystemDetail {...params}/> </Route>

					<Route path="/contact" exact> <Contact {...params}/> </Route>

					<Route path="/404" exact> <P404 {...params}/> </Route>
					<Redirect push to="/404" />
				</Switch>
				<Footer {...params} />
			</BrowserRouter>
		)
	}
}
