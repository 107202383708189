import React, { Component } from "react";
import { getPerson } from '../utils/request';
import { Link } from 'react-router-dom'
import { scrollTo } from "../utils/global";

export class Person extends Component {
    constructor(props) {
        super(props);

        this.state = {
            person : [],
			update: false
        };
    }

    componentDidMount() {
		getPerson().then((res)=>{
			this.setState({ person : res });
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    render() {
        const { person } = this.state
        return (
            <div className="page-content-wrapper section-space--inner--120" id="Person">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common-page-content">
                                <div className="common-page-text-wrapper section-space--bottom--50">
                                    <h2 className="common-page-title">Person</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-grid-wrapper">
                                <div className="row">
                                    {person.map((p,i)=>(
                                        <div className="col-lg-4 col-md-6" key={i}>
                                            <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                                                <div className="blog-post-slider__image section-space--bottom--30">
                                                    <Link to={"/person/"+p.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                        <img src={p.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                                    </Link>
                                                </div>
                                                <div className="blog-post-slider__content">
                                                    <h3 className="post-title">
                                                        <Link to={"/person/"+p.SLUG} className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{p.NAME}</Link>
                                                    </h3>
                                                    <p className="post-date">{p.POSITION}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Person;