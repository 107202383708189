import React, { Component } from "react";
import { getClientLanding } from '../utils/request';
import { withRouter } from "react-router";
import { appendScript, removeElement } from '../utils/dom';

export class TestimonialSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "ProductDetailScript",
                src : "/js/component/ProductDetail.js",
                async : true,
            },
            remove : ".ProductDetailScript", // string or array
            testi : [],
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
        getClientLanding().then((res)=>{
            this.setState({ testi : res })
            appendScript(attrs,remove)
        })
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }

    render() {
        const { testi } = this.state
        return (
            <div className="service-title-TestimonialSlider-detail-wrapper">
                <div className="service-details-text-wrapper section-space--bottom--60">
                    <h2 className="title">Happy Clients Say</h2>
                </div>
                <div className="TestimonialSlider-detail-slider__content-area TestimonialSlider-detail-slider__content-area--style2 mt-0">
                    <div className="swiper-container TestimonialSlider-detail-slider__container--style2">
                        <div className="swiper-wrapper TestimonialSlider-detail-slider__wrapper">
                            {testi.map((t,i)=>(
                                <div className="swiper-slide" key={i}>
                                    <div className="TestimonialSlider-detail-slider__single-item TestimonialSlider-detail-slider__single-item--style2">
                                        <div className="TestimonialSlider-detail-slider__single-item__image">
                                            <img src={t.FULL_PATH_IMAGE} className="img-fluid" alt="" style={{height:"100px"}}/>
                                        </div>
                                        <div className="testimonial-slider__single-item__content" style={{marginTop:"20px"}}>
                                            <h4 className="testimonial-name" style={{marginBottom:"0px"}}> {t.NAME}</h4>
                                            <div className="rating">
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                {/* <i className="ion-ios-star-outline"></i> */}
                                            </div>
                                            <div className="quote-icon">
                                                <i className="ion-quote"></i>&nbsp;
                                                <span>{t.TESTIMONIE}</span>&nbsp;
                                                <i className="ion-quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="swiper-pagination swiper-pagination-7"></div>
                </div>
            </div>

        )
    }
}

export default withRouter(TestimonialSlider)