import React, { Component } from "react";
import { appendScript, removeElement } from '../utils/dom';

export class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "ContactUsScript",
                src : "/js/component/ContactUs.js",
                async : true,
            },
            remove : ".ContactUsScript", // string or array
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
        appendScript(attrs,remove)
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    render() {
        return (
            <div className="map-newsletter-area section-space--inner--120" id="Contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="google-map google-map--style3" id="google-map-one" data-width="100%" data-zoom_enable="" data-zoom="10" data-map_type="roadmap"></div>
                        </div>
                        <div className="col-lg-4">
                            <div className="newsletter-box-wrapper h-100 bg-img" data-bg={"assets/img/icons/message.png"}>
                                <h3 className="newsletter-title newsletter-title--white">Subscribe for our latest topics</h3>
                                <div className="newsletter-form-wrapper">
                                    <form action="#">
                                        <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                        <button type="submit">SUBSCRIBE</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactUs;