import React, { Component } from 'react'
import { isLangEn } from '../utils/lang';
import { getEvents } from '../utils/request';
import { Link } from 'react-router-dom'
import { scrollTo } from "../utils/global";
import { paginationDom, } from "../utils/dom";
import $ from 'jquery'
import { withRouter } from "react-router";

export class EventPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bc : false,
            acts : [],
            total : 0,
            limit : 9,
            currentPage : 1,
            btnPages : [],
            update : false,
        };

		this.goTo = this.goTo.bind(this)
    }

    componentDidMount() {
        var btnPages = []
        const { limit, currentPage, } = this.state
		getEvents({limit:limit, pagin:currentPage}).then((res)=>{
            for(var i=1; i<=Math.ceil(res.total/limit); i++){
                btnPages.push(i)
            }
            this.setState({
                acts:res.events,
                total:res.total,
                btnPages:btnPages,
            })
            this.setBreadcrumb()
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { update, } = this.state
		if(prevProps.update===true || update===true){
            this.componentDidMount()
            this.setState({ update:false })
        }
    }

    setBreadcrumb(){
        const { bc } = this.state
        const { setBreadcrumb } = this.props

        if(bc===false){
            var title = (isLangEn())?"Event":"Event"
            setBreadcrumb(title, [{name:title, link:"/event"}])
            this.setState({ bc:true })
        }
    }

    goTo(page){
        const { update } = this.state
        this.setState({ currentPage:page, update:true })
        setTimeout(function(){
            if(update===false)
                scrollTo({target:$("#Event")[0]})
        },500)
    }

    render() {
        const { location, } = this.props
        const { acts, currentPage, btnPages, } = this.state
        if(!Array.isArray(acts)){ return ("") }
        console.log(acts)
        return (
            <main>
                <div role="main" className="main">
                    <div className="page-content-wrapper section-space--inner--top--120 grey-bg" id="Event" href={location.pathname+"#Event"}>
                        <div className="container">
                            <div className="category-grid-wrapper section-space--bottom--m30">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="common-page-content">
                                            <div className="common-page-text-wrapper section-space--bottom--50">
                                                <h2 className="common-page-title section-space--bottom--40">Event</h2>
                                            </div>
                                        </div>
                                    </div>
                                    {(acts.length<=6 || window.navigator.userAgentData.mobile===true)?
                                        (<div className="col-lg-8 text-right"></div>)
                                    :
                                        (<div className="col-lg-8 text-right">
                                            {paginationDom(currentPage,btnPages,this.goTo)}
                                        </div>)
                                    }
                                </div>
                                <div className="row">
                                    {acts.map((a,i)=>(
                                        <div className="col-lg-4 col-md-6" key={i}>
                                            <div className="single-case-study-project">
                                                <Link to={"/event/"+a.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                    <div className="single-case-study-project__image">
                                                        <img src={a.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                                    </div>
                                                </Link>
                                                <div className="single-case-study-project__content">
                                                    <h3 className="title">
                                                        <Link to={"/event/"+a.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>{a.NAME}</Link>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="row section-space--inner--bottom--120">
                                    <div className="col text-center">
                                        {paginationDom(currentPage,btnPages,this.goTo)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(EventPage)
