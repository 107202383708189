import React, { Component } from 'react'
import { withRouter } from "react-router";
import { isLangEn } from '../utils/lang';
// import { appendScript, removeElement } from '../utils/dom';
import ReactHtmlParser from 'react-html-parser';
import { getDetailPerson } from '../utils/request';
import { Share } from '../components';
import { Redirect, } from "react-router-dom";

export class PersonDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "PersonDetailScript",
                src : "/js/component/PersonDetail.js",
                async : true,
            },
            remove : ".PersonDetailScript", // string or array
            person : {},
			update: false,
            bc : false,
        };
    }

    componentDidMount() {
        const { match } = this.props
        // const { attrs, remove } = this.state
        
		getDetailPerson({slug:match.params.id}).then((res)=>{
            this.setState({ person : res, bc : false })
            if(res===undefined){ return }
            this.setBreadcrumb()
            // appendScript(attrs,remove)
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match } = this.props

        if(prevProps.update===true || prevProps.match.params.id!==match.params.id){
            this.componentDidMount()
        }
        
    }

    componentWillUnmount(){
        // const { remove } = this.state
        // removeElement(remove)
    }

    setBreadcrumb(){
        const { bc } = this.state
        const { setBreadcrumb } = this.props

        if(bc===true){ return }

        var title = (isLangEn())?"Person":"Orang"
        var arr = [{name:title, link:"/person"}]

        setBreadcrumb(title, arr)
        this.setState({ bc:true })
    }

    render() {
        const { person } = this.state
        if(person===undefined){ return <Redirect push to="/404" /> }
        return (
            <main>
                <div role="main" className="main">
                    <div className="page-content-wrapper section-space--inner--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <img src={person.FULL_PATH_IMAGE} className="img-fluid" alt="" />
                                </div>
                                <div className="col-lg-6">
                                    <h3 className="title">{person.NAME}</h3>
                                    <p className="title">{person.POSITION}</p>
                                    { ReactHtmlParser(person.DESC_PERSON) }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="float-right">
                                        <Share/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(PersonDetail)
