import React, { Component } from 'react'
import { withRouter } from "react-router";
import ReactHtmlParser from 'react-html-parser';
import { getDetailNews, getNewsNextPrev, getNewsLanding, } from '../utils/request';
import { Redirect, } from "react-router-dom";
import { Link } from 'react-router-dom'
import { scrollTo } from "../utils/global";
import { dmy } from "../utils/date"
import { Share } from "../components"

export class NewsDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            news : {},
			update: false,
            bc : false,
            next : {},
            prev : {},
            recent : [],
            route : "",
        };
    }

    componentDidMount() {
        const { match } = this.props
        
		getDetailNews({slug:match.params.id}).then((res)=>{
            if(res===undefined){ return }

            let next = false, prev = false
            getNewsNextPrev({slug:match.params.id}).then((np)=>{
                if(Array.isArray(np)){
                    if(np.length>=2){
                        next = np[1]
                        prev = np[0]
                    }else if(np.length===1){
                        prev = np[0]
                    }
                }

                getNewsLanding({limit:4,category:res.category_id}).then((rec)=>{
                    this.setState({ news:res, bc:false, next:next, prev:prev, recent:rec })
                    this.setBreadcrumb()
                })
            })
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match } = this.props

        if(prevProps.update===true || prevProps.match.params.id!==match.params.id){
            this.componentDidMount()
        }
        
    }

    setBreadcrumb(){
        const { main } = this.props
        const { bc, news } = this.state
        const { setBreadcrumb, match } = this.props

        if(bc===true){ return }

        var title = (main.language)? main.language.MENU_NEWS : "-"
        var arr = [{name:title, link:"/news"}]

        if(match.params.hasOwnProperty("id")){
            var id = match.params.id
            var name = news.TITLE
            
            arr.push({name:name,link:"/"+title.toLowerCase()+"/"+id})
        }

        setBreadcrumb(title, arr)

        let route = (news.category_id===1)? "activity" : "article"
        this.setState({ bc:true, route:"/"+route+"/" })
    }

    render() {
        const { news, prev, next, recent, route } = this.state
        if(news===undefined){ return <Redirect push to="/404" /> }
        let tags = (news.TAG===undefined)? [] : news.TAG.split(",")
        return (
            <main>
                <div role="main" className="main">
                    <div className="page-content-wrapper section-space--inner--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="blog-content-wrapper">
                                        <div className="single-list-blog-post mb-0">
                                            <div className="single-list-blog-post__content single-list-blog-post__content--style2">
                                                <h1 className="title">{news.TITLE}</h1>
                                                <div className="meta-list">
                                                    <div className="single-meta">
                                                        <p className="date">{dmy(news.DATE_NEWS)}</p>
                                                    </div>
                                                    <div className="single-meta">
                                                        <p className="author">{news.SUB_TITLE}</p>
                                                    </div>
                                                </div>
                                                <div className="post-text">
                                                    <div style={{float:"left",marginRight:"10px",width:"40%"}}>
                                                        <img src={news.FULL_PATH_IMAGE} className="img-fluid" alt="" style={{width:"100%",height:"auto"}}/>
                                                    </div>
                                                    {ReactHtmlParser(news.DESC_NEWS)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tag-list-wrapper">
                                            <span className="tag-title">TAGS</span>
                                            <ul className="tag-list">
                                                {tags.map((n,i)=>{
                                                    if(n===""){ return("") }
                                                    return (<li key={i}><a href="/news" onClick={(e)=>e.preventDefault()}>{n}</a></li>)
                                                })}
                                            </ul>
                                            <div className="float-right">
                                                <Share/>
                                            </div>
                                        </div>

                                        <div className="blog-post-nav">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {(prev===false)?<div></div>:
                                                        <div className="blog-post-nav__prev">
                                                            <Link to={route+prev.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                                <div className="post-nav-link"><i className="ion-chevron-left"></i> PREVIOUS </div>
                                                                <div className="post-nav-title">{prev.TITLE}</div>
                                                            </Link>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-md-6">
                                                    {(next===false)?<div></div>:
                                                        <div className="blog-post-nav__next text-left text-md-right">
                                                            <Link to={route+next.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                                <div className="post-nav-link"> NEXT <i className="ion-chevron-right"></i></div>
                                                                <div className="post-nav-title">{next.TITLE}</div>
                                                            </Link>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="page-sidebar">
                                        <div className="single-sidebar-widget">
                                            <h2 className="widget-title">Recent News</h2>
                                            <div className="sidebar-recent-post-list">
                                                {recent.map((r,i)=>(
                                                    <div className="sidebar-recent-post" key={i}>
                                                        <div className="sidebar-recent-post__image">
                                                            <Link to={route+r.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                                <img src={r.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                                            </Link>
                                                        </div>
                                                        <div className="sidebar-recent-post__content">
                                                            <h3 className="title">
                                                                <Link to={route+r.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>
                                                                    {r.TITLE}
                                                                </Link>
                                                            </h3>
                                                            <p className="post-date">{dmy(r.DATE_NEWS)}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(NewsDetail)
