import $ from 'jquery'
import { setLang } from './lang';

export const formattingCurrency = (v) => {
    if(v===undefined){ return "" }
    var res = ""
    v = v.split("").reverse()
    for(var j=0; j<v.length; j++){
        res += v[j]
        res += ((j+1)%3===0)? "." : ""
    }
    res = res.split("").reverse().join("")
    return res
}

export const scrollTo = (el=false) => {
    let getCoord = function(el,split){
        el = $("#"+split[1])
        el = el[0]
        var yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        var yOffset = (window.pageYOffset===0)? $("#header .header-area")[0].offsetHeight*-1 : 0
        return yCoordinate + yOffset
    }

    if(el===false){
        let href = window.location.href
        let split = href.split("#")
        let interval = setInterval(function(){
            let top = 0
            if($("#"+split[1]).length>0){
                top = getCoord(false,split)
            }
            window.scrollTo({ top: top, behavior: 'smooth' })
            clearInterval(interval)
        },window.g.intervalLoader)
        return
    }

    
    let link = false
    if($(el.target).attr("href")===undefined){
        link = $(el.target).parents("a").attr("href")
    }else{
        link = $(el.target).attr("href")
    }

    if(link===false){
        return
    }

    let split = link.split("#")

    if(link.includes("#") && split[0]===window.location.pathname){
        window.scrollTo({ top: getCoord(el,split), behavior: 'smooth' })
        return
    }else if(link.includes("#") && split[0]!==window.location.pathname){
        el = $("#"+split[1])

        var hashLinkInterval = setInterval(function(){
            el = $("#"+split[1])

            if(el.length>0){
                el = $("#"+split[1])
                window.scrollTo({ top: getCoord(el,split), behavior: 'smooth' })
                clearInterval(hashLinkInterval)
            }
        },window.g.intervalLoader/2)
        return
    }

    window.scrollTo({ top: 0, behavior: 'smooth' })
    return
}

export const global = () => {

    if(localStorage.getItem("lang")==null){
        localStorage.setItem('lang', "en")
    }

    setLang(localStorage.getItem("lang"))

    window.paramLang = (localStorage.getItem("lang")==null)? "" : "?lang="+localStorage.getItem("lang")
    // window.paramLang = ""
    
    window.g = {
        scrollTo : scrollTo,
        formattingCurrency : formattingCurrency,
        intervalLoader : 2000,
        loopTime : 500,
        log : {},
        limit : 100,
        completed : [],
        doLimit : function(t){
            let wg = window.g
            let wLog = wg.log
            while(wLog[t].length>=wg.limit){
                wLog[t].shift()
            }
        },
        exist : function(t,i=false){
            let wg = window.g
            let wLog = wg.log
            i = (i===false)? t : i
            if($(document).find(t).length<1){
                wLog[i].push(["Element ["+t+"] not found", new Date()])
                return false
            }else{
                return true
            }
        },
        clearInterval : function(t){
            let wg = window.g
            let wLog = wg.log
            if(wLog[t].length>wg.limit){
                wg.complete(t,true,"incomplete")
                // clearInterval(wg[t])
                return false
            }else{
                return true
            }
        },
        imgComplete : function(t,imgFind="img"){
            let wg = window.g
            let wLog = wg.log
            let imgs = $(t).find(imgFind)
            if(imgs.length<1){
                wg.doLimit(t)
                wLog[t].push(["Element ["+t+" "+imgFind+"] not found", new Date()])
                return false
            }
            
            for(var i=0; i<imgs.length; i++){
                var img = imgs[i]
                if(img.complete===false){
                    wg.doLimit(t)
                    wLog[t].push(["Element ["+t+" img:nth-child("+(i+1)+")] incomplete", new Date()])
                    return false
                }
            }
            
            return true
        },
        complete : function(i,push=true,message="complete"){
            let wg = window.g
            let wLog = wg.log
            if(push===true){
                wLog[i].push([message,new Date()])
            }
            wg.completed[i] = true
            // clearInterval(wg[i])
        },
        reset : function(i){
            let wg = window.g
            // let wLog = wg.log
            // wLog[i] = (wLog[i])? wLog[i] : []
            wg.completed[i] = false
        },
        loop : (id,time,f)=>new Promise(r=>{
            if(f()===false){
                setTimeout(()=>{
                    window.g.loop(id,time,f)
                },time);
            }
        })
    }

}
