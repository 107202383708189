import axios from "axios";
import {
    API_SET_LANGUAGE,
    API_MAIN,
    API_SERVICES,
    API_BANNERS,
    API_PERSON_EXPERT,
    API_GET_CLIENT_PRODUCT,
    API_PERSON_LEADER,
    API_NEWS_LANDING,
    API_DETAIL_SERVICE,
    API_CLIENT_LANDING,
    API_DETAIL_CLIENT,
    API_DETAIL_CLIENT_PRODUCT,
    API_GET_DOCUMENT,
    API_NEWS,
    API_DETAIL_NEWS,
    API_PERSON,
    API_DETAIL_PERSON,
    API_NEWS_NEXT_PREV,
    API_EVENTS,
    API_PAGES,
    API_DETAIL_EVENTS,
    API_DETAIL_PAGE,
    API_GET_CLIENT_PRODUCT_LANDING,
    API_STORYLINE,
    API_DETAILS_PAGE,
    API_PORTFOLIO_CLIENT,
    API_SERVICE_CLIENT,
    API_CLIENT_PRODUCT_BY_CLIENT,
} from './constants';

const getParams = (p=null) => {
    let params = window.paramLang + ""

    if(p===null){ return params }

    for(var i in p){
        var separator = (params.includes("?"))? "&" : "?"
        params += separator + i + "=" + p[i]
    }

    return params
}

export const getProductByClient = (p) => {
    const headers = {headers : {}}

    return axios.get(API_CLIENT_PRODUCT_BY_CLIENT+getParams(p), headers).then((res) => {
        return res.data.client_products;
    }).catch((error) => {
        console.log("Client Product : ", error);
    })
}

export const getServiceClient = (p) => {
    const headers = {headers : {}}

    return axios.get(API_SERVICE_CLIENT+getParams(p), headers).then((res) => {
        return res.data.clients_services;
    }).catch((error) => {
        console.log("Service : ", error);
    })
}

export const getPortfolioClient = (p) => {
    const headers = {headers : {}}

    return axios.get(API_PORTFOLIO_CLIENT+getParams(p), headers).then((res) => {
        return res.data.clients_portofolios;
    }).catch((error) => {
        console.log("Portofolio : ", error);
    })
}

export const getDetailsPage = (p) => {
    const headers = {headers : {}}

    return axios.get(API_DETAILS_PAGE+getParams(p), headers).then((res) => {
        return res.data.pages_detail;
    }).catch((error) => {
        console.log("Details Page : ", error);
    })
}

export const getStoryLanding = () => {
    const headers = {headers : {}}

    return axios.get(API_STORYLINE+getParams(), headers).then((res) => {
        return res.data.story_line;
    }).catch((error) => {
        console.log("Story : ", error);
    })
}

export const getClientProductLanding = () => {
    const headers = {headers : {}}

    return axios.get(API_GET_CLIENT_PRODUCT_LANDING+getParams(), headers).then((res) => {
        return res.data.client_products;
    }).catch((error) => {
        console.log("Client Product : ", error);
    })
}

export const getDetailPages = (p) => {
    const headers = {headers : {}}

    return axios.get(API_DETAIL_PAGE+getParams(p), headers).then((res) => {
        return res.data.pages;
    }).catch((error) => {
        console.log("Event : ", error);
    })
}

export const getDetailEvents = (p) => {
    const headers = {headers : {}}

    return axios.get(API_DETAIL_EVENTS+getParams(p), headers).then((res) => {
        return res.data.events;
    }).catch((error) => {
        console.log("Event : ", error);
    })
}

export const getPages = () => {
    const headers = {headers : {}}

    return axios.get(API_PAGES+getParams(), headers).then((res) => {
        return res.data.pages;
    }).catch((error) => {
        console.log("pages : ", error);
    })
}

export const getEvents = () => {
    const headers = {headers : {}}

    return axios.get(API_EVENTS+getParams(), headers).then((res) => {
        return res.data;
    }).catch((error) => {
        console.log("Event : ", error);
    })
}

export const getNewsNextPrev = (p) => {
    const headers = {headers : {}}

    return axios.get(API_NEWS_NEXT_PREV+getParams(p), headers).then((res) => {
        return res.data.news;
    }).catch((error) => {
        console.log("News : ", error);
    })
}

export const getDetailPerson = (p) => {
    const headers = {headers : {}}

    return axios.get(API_DETAIL_PERSON+getParams(p), headers).then((res) => {
        return res.data.person;
    }).catch((error) => {
        console.log("Person : ", error);
    })
}

export const getPerson = () => {
    const headers = {headers : {}}

    return axios.get(API_PERSON+getParams(), headers).then((res) => {
        return res.data.person;
    }).catch((error) => {
        console.log("Person : ", error);
    })
}

export const getDetailNews = (p) => {
    const headers = {headers : {}}

    return axios.get(API_DETAIL_NEWS+getParams(p), headers).then((res) => {
        return res.data.news;
    }).catch((error) => {
        console.log("News : ", error);
    })
}

export const getNews = (p) => {
    const headers = {headers : {}}

    return axios.get(API_NEWS+getParams(p), headers).then((res) => {
        return res.data;
    }).catch((error) => {
        console.log("News : ", error);
    })
}

export const getDocumentLanding = () => {
    const headers = {headers : {}}

    return axios.get(API_GET_DOCUMENT+getParams(), headers).then((res) => {
        return res.data.documents;
    }).catch((error) => {
        console.log("Document : ", error);
    })
}

export const getDetailClientProduct = (p) => {
    const headers = {headers : {}}

    return axios.get(API_DETAIL_CLIENT_PRODUCT+getParams(p), headers).then((res) => {
        return res.data.client_products;
    }).catch((error) => {
        console.log("Client Product : ", error);
    })
}

export const getDetailClient = (p) => {
    const headers = {headers : {}}

    return axios.get(API_DETAIL_CLIENT+getParams(p), headers).then((res) => {
        return res.data.clients;
    }).catch((error) => {
        console.log("Client : ", error);
    })
}

export const getClientLanding = () => {
    const headers = {headers : {}}

    return axios.get(API_CLIENT_LANDING+getParams(), headers).then((res) => {
        return res.data.clients;
    }).catch((error) => {
        console.log("Client : ", error);
    })
}

export const getDetailService = (p) => {
    const headers = {headers : {}}

    return axios.get(API_DETAIL_SERVICE+getParams(p), headers).then((res) => {
        return res.data.services;
    }).catch((error) => {
        console.log("Program : ", error);
    })
}

export const getNewsLanding = (p) => {
    const headers = {headers : {}}

    return axios.get(API_NEWS_LANDING+getParams(p), headers).then((res) => {
        return res.data.news;
    }).catch((error) => {
        console.log("News : ", error);
    })
}

export const getPersonLeader = () => {
    const headers = {headers : {}}

    return axios.get(API_PERSON_LEADER+getParams(), headers).then((res) => {
        return res.data.person;
    }).catch((error) => {
        console.log("Person Leader : ", error);
    })
}

export const getClientProduct = (p={}) => {
    const headers = {headers : {}}

    return axios.get(API_GET_CLIENT_PRODUCT+getParams(p), headers).then((res) => {
        return res.data.client_products;
    }).catch((error) => {
        console.log("Client Product : ", error);
    })
}

export const getPersonExpert = () => {
    const headers = {headers : {}}

    return axios.get(API_PERSON_EXPERT+getParams(), headers).then((res) => {
        return res.data.person;
    }).catch((error) => {
        console.log("Person Export : ", error);
    })
}

export const getBanner = () => {
    const headers = {headers : {}}

    return axios.get(API_BANNERS+getParams(), headers).then((res) => {
        return res.data.sliders;
    }).catch((error) => {
        console.log("Banner : ", error);
    })
}

export const getService = () => {
    const headers = {headers : {}}

    return axios.get(API_SERVICES+getParams(), headers).then((res) => {
        return res.data.services
    }).catch((error) => {
        console.log("Service : ", error);
    })
}

export const getMain = () => {
    const headers = {headers : {}}

    return axios.get(API_MAIN+getParams(), headers).then((res) => {
        return res.data.main
    }).catch((error) => {
        console.log("Main : ", error);
    });
}

export const setLanguage = (v) => {
    const data = {lang:v}
    const headers = {headers : {}}

    return axios.post(API_SET_LANGUAGE, data, headers).then((res) => {
        return res
    }).catch((error) => {
        console.log("Language : ", error);
    })
}
