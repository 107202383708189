import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

export class VisionMission extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
		const {main} = this.props
        const title = (this.props.visiMisiTag!==undefined)? this.props.visiMisiTag.title
            : (main.language)? main.language.MENU_VISIMISI : "-"
        return (
            <div className="page-content-wrapper section-space--inner--top--120" id="VisionMission">
                <div className="container">
                    {(this.props.visiMisiTag!==undefined && this.props.visiMisiTag.removeHeader===true)?
                        <div></div>
                    :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-title-area text-center section-space--bottom--50">
                                    <h2 className="title">{ title }</h2>
                                    <p className="subtitle">Professional ethics govern the values of social responsibility that help us deliver our obligations within a coherent social framework.</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common-page-content">
                                <div className="common-page-text-wrapper section-space--bottom--50">
                                    { ReactHtmlParser(main.VISI) }
                                    <br/>
                                    <br/>
                                    { ReactHtmlParser(main.MISI) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default VisionMission;