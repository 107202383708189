import React, { Component } from "react";


export class TenantVideo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: "",
			update: false,
        };
    }

    componentDidMount() {
        const { url } = this.props
        this.setState({ url:url })
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true || prevProps.url!==this.props.url){
            this.componentDidMount()
        }
    }

    render() {
        const { main } = this.props
        const { url } = this.state
        const title = (this.props.TenantVideo!==undefined)? this.props.TenantVideo.title
            : (main.language)? main.language.COMPANY_PROFILE : "-"
        if([undefined,""].includes(url)){ return("") }
        return (
            <div className="product-member-slider-area section-space--inner--top--40" id="Video">
                <div className="container">
                    {(this.props.TenantVideo!==undefined && this.props.TenantVideo.removeHeader===true)?
                        <div></div>
                    :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-title-area text-center section-space--bottom--50">
                                    <h2 className="title">{ title }</h2>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <iframe width="595" height="340" src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TenantVideo;