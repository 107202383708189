import React, { Component } from "react";
import { appendScript, removeElement } from '../utils/dom';
import { Menu } from './Menu';
import { scrollTo } from "../utils/global";
import { Link } from "react-router-dom";
import { API_URL } from '../utils/constants';
import { withRouter } from "react-router";

export class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "HeaderScript",
                src : "/js/component/Header.js",
                async : true,
            },
            remove : ".HeaderScript", // string or array
            title : this.props.breadcrumb.title,
            breadcrumb : this.props.breadcrumb.breadcrumb,
            url : {
                tunnel : "https://admin.web.ecosystem.penggiat-riset2.com/uploads/img/sliders/618638f6cf145.jpg",
                meeting : "https://admin.web.ecosystem.penggiat-riset2.com/uploads/img/sliders/618638fdd212d.jpg",
                building : "https://admin.web.ecosystem.penggiat-riset2.com/uploads/img/sliders/618639045b111.jpg"
            },
        }
    }

    componentDidMount() {
        const { attrs, remove } = this.state
        appendScript(attrs,remove)
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.updateBreadcrumb===true){
            this.setState({
                title : this.props.breadcrumb.title,
                breadcrumb : this.props.breadcrumb.breadcrumb,
            })
        }
    }

    bgCheck(p=[]){
        const { location } = this.props
        let path = location.pathname

        for(var i=0; i<p.length; i++){
            if(path.indexOf("/"+p[i])!==-1){
                return true
            }
        }

        return false
    }

    bg(){
        const { url } = this.state

        if(this.bgCheck(["ecosystem","gallery","product","tenant"])){
            return url.tunnel
        }else if(this.bgCheck(["about","person"])){
            return url.meeting
        }else if(this.bgCheck(["news","contact","article","activity"])){
            return url.building
        }

        return "assets/img/backgrounds/19.jpg"
    }
    
    render() {
        const { main, setLang } = this.props
        const { title, breadcrumb } = this.state
        
        return (
            <header id="header" data-title={main.NAMA_CLIENT}>
                <div className="prldr preloader-activate preloader-active">
                    <div className="preloader-area-wrap">
                        <div className="spinner d-flex justify-content-center align-items-center h-100">
                            <img src={"assets/img/preloader.gif"} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="header-area header-sticky">
                    <div className="header-area__desktop">
                        <div className="header-top-bar">
                            <div className="container-fluid container-fluid--cp-60">
                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <div className="top-bar-left-wrapper">
                                            <ul className="topbar-menu">
                                                <li><a href={main.FB} target="_blank" rel="noreferrer noopener" data-tippy="Facebook" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom" title="Facebook"><i className="ion-social-facebook"></i></a>
                                                </li>
                                                <li><a href={main.TW} target="_blank" rel="noreferrer" data-tippy="Twitter" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom" title="Twitter"><i className="ion-social-twitter"></i></a>
                                                </li>
                                                <li><a href={main.LI} target="_blank" rel="noreferrer" data-tippy="Linkedin" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom" title="Linkedin"><i className="ion-social-linkedin"></i></a>
                                                </li>
                                                <li><a href={main.IG} target="_blank" rel="noreferrer" data-tippy="Instagram" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom" title="Instagram"><i className="ion-social-instagram"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="top-bar-right-wrapper">
                                            <ul className="topbar-info">
                                                <li><a href={"mailto:"+main.EMAIL}>
                                                        <i className="ion-ios-email-outline"></i>
                                                        {main.EMAIL}
                                                    </a>
                                                </li>
                                                {/* <li><i className="ion-clock"></i> Mon - Fri: 9:00 - 19:00 / Closed on Weekends</li> */}
                                            </ul>
                                            <div className="language-change-wrapper">
                                                <a href="#lang" className="language-change-trigger">
                                                    <div className="language-flag">
                                                        <img src={API_URL + "uploads/static/"+ localStorage.getItem("lang") +".png"} className="img-fluid" alt=""/>
                                                    </div>
                                                    <span className="selected-lang text-uppercase">{localStorage.getItem("lang")} <i className="ion-chevron-down"></i></span>
                                                </a>
                                                <ul className="language-dropdown">
                                                    <li onClick={(e) => setLang("en")}>
                                                        <a href="#en"><img src={API_URL + "uploads/static/en2.png"} className="img-fluid" alt=""/> EN</a>
                                                    </li>
                                                    <li onClick={(e) => setLang("id")}>
                                                        <a href="#id"><img src={API_URL + "uploads/static/id.png"} className="img-fluid" alt=""/> ID</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="header-navigation-area">
                            <div className="container-fluid container-fluid--cp-60">
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* <div className="header-navigation-wrapper"> */}
                                        <div className="header-wrapper__inner header-wrapper__inner  position-relative">
                                            <div className="logo">
                                                <a href="/home">
                                                    <img src={main.FULLPATH_LOGO_CLIENT} className="img-fluid" alt="" width="160px"/>
                                                </a>
                                            </div>

                                            <div className="header-navigation" style={{"flexBasis":"100%"}}>
                                                {/* <div className="header-navigation__nav"> */}
                                                <div className="header-navigation__nav header-navigation__nav--style4 position-static" style={{justifyContent:"right"}}>
                                                    <nav>
														<Menu {...this.props}></Menu>
                                                    </nav>
                                                </div>
                                                <div className="header-navigation__contact">
                                                    <div className="header-navigation__contact__image">
                                                        <i className="ion-ios-telephone-outline"></i>
                                                    </div>
                                                    <div className="header-navigation__contact__content">
                                                        <span className="text">{ (main.language)? main.language.MENU_CONTACT : null }</span>
                                                        <h4 className="sub-text">
                                                            <a href={"tel:"+main.NO_TELP} className="text-decoration-none" target="_blank" rel="noreferrer" s="s" title="Call Us" >
                                                                <span className="custom-call-to-action-2 text-color-light text-2 custom-opacity-font">
                                                                <span className="info text-4">
                                                                    <font color="black">{main.NO_TELP}</font>
                                                                </span>
                                                                </span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="header-search" style={{display:"none"}}>
                                                <div className="search-form">
                                                    <form action="#">
                                                        <input type="text" placeholder="Keyword search..."/>
                                                        <button><i className="ion-ios-search-strong"></i></button>
                                                    </form>
                                                </div>
                                                <div className="social-links">
                                                    <ul>
                                                        <li><a href="//facebook.com" data-tippy="Facebook" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-facebook"></i></a>
                                                        </li>
                                                        <li><a href="//twitter.com" data-tippy="Twitter" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-twitter"></i></a>
                                                        </li>
                                                        <li><a href="//vimeo.com" data-tippy="Vimeo" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-vimeo"></i></a></li>
                                                        <li><a href="//linkedin.com" data-tippy="Linkedin" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-linkedin"></i></a>
                                                        </li>
                                                        <li><a href="//skype.com" data-tippy="Skype" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-skype"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-area__mobile">
                        <div className="mobile-menu-area">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-sm-6 col-5">
                                        <div className="logo">
                                            <a href="/home">
                                                <img src={main.FULLPATH_LOGO_CLIENT} className="img-fluid" alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-sm-6 col-7">
                                        <div className="mobile-menu-content">
                                            <div className="social-links d-none d-md-block">
                                                <ul>
                                                    <li><a href="//facebook.com" data-tippy="Facebook" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-facebook"></i></a></li>
                                                    <li><a href="//twitter.com" data-tippy="Twitter" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-twitter"></i></a></li>
                                                    <li><a href="//vimeo.com" data-tippy="Vimeo" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-vimeo"></i></a></li>
                                                    <li><a href="//linkedin.com" data-tippy="Linkedin" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-linkedin"></i></a></li>
                                                    <li><a href="//skype.com" data-tippy="Skype" data-tippy-inertia="false" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder__yellow" data-tippy-placement="bottom"><i className="ion-social-skype"></i></a></li>
                                                </ul>
                                            </div>
                                            <div className="language-change-wrapper">
                                                <a href="#ahref" className="language-change-trigger language-change-trigger--mobile">
                                                    <div className="language-flag">
                                                        <img src={API_URL + "uploads/static/"+ localStorage.getItem("lang") +".png"} className="img-fluid" alt=""/>
                                                    </div>
                                                    <span className="selected-lang text-uppercase">{localStorage.getItem("lang")} <i className="ion-chevron-down"></i></span>
                                                </a>
                                                <ul className="language-dropdown">
                                                    <li onClick={(e) => setLang("en")}>
                                                        <a href="#en"><img src={API_URL + "uploads/static/en2.png"} className="img-fluid" alt=""/> EN</a>
                                                    </li>
                                                    <li onClick={(e) => setLang("id")}>
                                                        <a href="#id"><img src={API_URL + "uploads/static/id.png"} className="img-fluid" alt=""/> ID</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mobile-navigation-icon" id="mobile-menu-trigger">
                                                <i></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(breadcrumb.length<1) ? <div></div> :
                    <div className="breadcrumb-area breadcrumb-area-bg section-space--inner--80 bg-img" data-bg={this.bg()} style={{backgroundImage:"url(" + this.bg() + ")"}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <h2 className="breadcrumb-page-title">{ title }</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ul className="breadcrumb-page-list text-uppercase">
                                        {breadcrumb.map((b,i)=>(
                                            (i===(breadcrumb.length-1)?
                                                <li key={i}>{ b.name }</li>
                                            :
                                                <li className="has-children" key={i}>
                                                    <Link to={b.link} className="text-uppercase" target="_self" onClick={(el)=>{ scrollTo(el) }}>{b.name}</Link>
                                                </li>
                                            )
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </header>
        );
    }
}

export default withRouter(Header);
