import $ from "jquery";

export const appendScript = (attrs={},remove=false) => {
    let si = setInterval(function(){
        var scr = $(".prldr")
        if(scr.length<1){ return }

        removeElement(remove)

        let script = document.createElement("script")
        for(var i in attrs){
            script.setAttribute(i,attrs[i])
        }
        
        $("body").append(script)
        clearInterval(si)
    }, 500)
}

export const removeElement = (v) => {
    $(v).remove()
}

export const paginationDom = (currentPage,btnPages,goTo) => {
    var last = btnPages.length
    var active = {borderColor:"transparent",marginLeft:"2px",marginRight:"2px"}
    var disabled = {backgroundColor:"#343a40",color:"#fff",marginLeft:"2px",marginRight:"2px"}
    var dot = {marginLeft:"2px",marginRight:"2px"}
    var maxPages = 10
    var statusLeft = false
    var statusRight = false
    return (
        <div>
            <button type="button" className={(currentPage===1)?"btn btn-default":"btn btn-outline-dark"} title="First Page" onClick={()=>{ if(currentPage>1){ goTo(1) } }} style={active}>
                <span className="ion-chevron-left"></span>
                <span className="ion-chevron-left"></span>
            </button>

            <button type="button" className={(currentPage===1)?"btn btn-default":"btn btn-outline-dark"} title="Previous Page" onClick={()=>{ if(currentPage>1){ goTo(currentPage-1) } }} style={active}>
                <span className="ion-chevron-left"></span>
            </button>

            {btnPages.map((b,i)=>{
                if(btnPages.length>maxPages && currentPage>b+2){
                    if(statusLeft===false){
                        statusLeft = true
                        return ([
                            <button type="button" className="btn btn-default" style={dot}>.</button>,
                            <button type="button" className="btn btn-default" style={dot}>.</button>
                        ])
                    }
                }
                if(btnPages.length>maxPages && currentPage<b-2){
                    if(statusRight===false){
                        statusRight = true
                        return ([
                            <button type="button" className="btn btn-default" style={dot}>.</button>,
                            <button type="button" className="btn btn-default" style={dot}>.</button>
                        ])
                    }
                }
                return (
                    <button key={i} type="button" title={"#"+b+" Page"} className={(b===currentPage)?"btn btn-default":"btn btn-outline-dark"} onClick={()=>{ if(currentPage!==b){ goTo(b) } }} style={(b===currentPage)?disabled:active}>
                        {b}
                    </button>)
            })}

            <button type="button" className={(currentPage===last)?"btn btn-default":"btn btn-outline-dark"} title="Next Page" onClick={()=>{ if(currentPage!==last){ goTo(currentPage+1) } }} style={active}>
                <span className="ion-chevron-right"></span>
            </button>

            <button type="button" className={(currentPage===last)?"btn btn-default":"btn btn-outline-dark"} title="Last Page" onClick={()=>{ if(currentPage!==last){ goTo(last) } }} style={active}>
                <span className="ion-chevron-right"></span>
                <span className="ion-chevron-right"></span>
            </button>
        </div>
    )
}