import React, { Component } from "react";
import { getPersonExpert } from '../utils/request';
import { Link } from 'react-router-dom'
import { scrollTo } from "../utils/global";
import { withRouter } from "react-router";

export class MentorsGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acts : [],
            total : 0,
            limit : 9,
            currentPage : 1,
            category : 1,
            btnPages : [],
            update : false,
        };
    }

    componentDidMount() {
		getPersonExpert().then((res)=>{
            this.setState({ acts:res, })
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    render() {
        const { main } = this.props
        const { acts, } = this.state
        const title = (this.props.MentoGridTag!==undefined)? this.props.MentoGridTag.title
            : (main.language)? main.language.MENU_MENTOR : "-"
        const id = (this.props.MentoGridTag!==undefined)? this.props.MentoGridTag.id
            : "Mentors"
        return (
            <div className="page-content-wrapper section-space--inner--top--120" id={id}>
                <div className="container">
                    <div className="category-grid-wrapper section-space--bottom--m30">
                        {(this.props.MentoGridTag!==undefined && this.props.MentoGridTag.removeHeader===true)?
                            <div></div>
                        :
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="career-title-area text-center section-space--bottom--50">
                                        <h2 className="title">{ title }</h2>
                                        <p className="subtitle">Professional ethics govern the values of social responsibility that help us deliver our obligations within a coherent social framework.</p>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row">
                            {acts.map((a,i)=>(
                                <div className="col-lg-4 col-md-6" key={i}>
                                    <div className="single-case-study-project">
                                        <Link to={"/person/"+a.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }} style={{width:"100%",height:"100%"}}>
                                            <div className="single-case-study-project__image">
                                                <img src={a.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                            </div>
                                        </Link>
                                        <div className="single-case-study-project__content">
                                            <h3 className="title">
                                                <Link to={"/person/"+a.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>{a.NAME}</Link>
                                            </h3>
                                            <p>{a.POSITION}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(MentorsGrid);