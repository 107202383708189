import React, { Component } from 'react'
import { withRouter } from "react-router";
import { isLangEn } from '../utils/lang';
import { appendScript, removeElement } from '../utils/dom';
import ReactHtmlParser from 'react-html-parser';
import { getDetailEvents } from '../utils/request';
import { Share } from '../components';
import { Redirect, } from "react-router-dom";

export class EventDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "EventDetailScript",
                src : "/js/component/EventDetail.js",
                async : true,
            },
            remove : ".EventDetailScript", // string or array
            event : {},
			update: false,
            bc : false,
        };
    }

    componentDidMount() {
        const { match } = this.props
        const { attrs, remove } = this.state
        
		getDetailEvents({slug:match.params.id}).then((res)=>{
            this.setState({ event : res, bc : false })
            if(res===undefined){ return }
            this.setBreadcrumb()
            appendScript(attrs,remove)
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match } = this.props

        if(prevProps.update===true || prevProps.match.params.id!==match.params.id){
            this.componentDidMount()
        }
        
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }

    setBreadcrumb(){
        const { bc, event } = this.state
        const { setBreadcrumb, match } = this.props

        if(bc===true){ return }

        var title = (isLangEn())?"Event":"Event"
        var arr = [{name:title, link:"/event"}]

        if(match.params.hasOwnProperty("id")){
            var id = match.params.id
            var name = event.NAME
            
            arr.push({name:name,link:"/event/"+id})
        }

        setBreadcrumb(title, arr)
        this.setState({ bc:true })
    }

    render() {
        const { event } = this.state
        if(event===undefined){ return <Redirect push to="/404" /> }
        return (
            <main>
                <div role="main" className="main">
                    <div className="page-content-wrapper section-space--inner--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div style={{float:"left",marginRight:"10px",width:"40%"}}>
                                        <img src={event.FULL_PATH_IMAGE} className="img-fluid" alt="" style={{width:"100%",height:"auto"}}/>
                                    </div>
                                    <h3 className="title">{event.NAME}</h3>
                                    { ReactHtmlParser(event.DESC_EVENT) }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="float-right">
                                        <Share/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(EventDetail)
