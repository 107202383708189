import React, { Component } from "react";
import { getDetailsPage } from '../utils/request';
import ReactHtmlParser from 'react-html-parser';


export class pageDetailAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            service : [],
			update: false
        };
    }

    componentDidMount() {
        const { pageId } = this.props
		getDetailsPage({pageId:pageId}).then((res)=>{
            this.setState({ service : res })
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true || prevProps.pageId!==this.props.pageId){
            this.componentDidMount()
        }
    }

    render() {
        const { service } = this.state
        if(Object.keys(service).length<1){ return("") }
        return (
            <div className="accordion" id="pageDetailAccordion">
                {Object.keys(service).map((d,i)=>{
                    d = service[i]
                    var classes = (i==="0")? "collapse show mt-1 p-3" : "collapse mt-1 p-3"
                    return(
                        <div className="card no-border mt-1" key={"accors"+i}>
                            <div className="card-header project-counter-two-area-bg cursor-pointer border-radius-50" id={"heading"+i} data-toggle="collapse" data-target={"#collapse"+i} aria-expanded="true" aria-controls={"collapse"+i}>
                                <h2 className="mb-0">
                                    <button className="btn text-left color-white accordion-arrow no-border" type="button" style={{width:"100%"}}>
                                        {d.TITLE}
                                    </button>
                                </h2>
                            </div>
                            <div id={"collapse"+i} className={classes} aria-labelledby={"heading"+i} data-parent="#pageDetailAccordion">
                                <div className="card-body with-border">
                                    {ReactHtmlParser(d.DESCRIPTION)}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default pageDetailAccordion;