import React, { Component } from 'react'
import { withRouter } from "react-router";
import ReactHtmlParser from 'react-html-parser';
import { getDetailClientProduct } from '../utils/request';
import { Redirect, } from "react-router-dom";
import { formattingCurrency } from "../utils/global";
import { Share } from "../components";

export class ProductDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product : {},
			update: false,
            bc : false,
        };
    }

    componentDidMount() {
        const { match } = this.props
        
		getDetailClientProduct({slug:match.params.id}).then((res)=>{
            this.setState({ product : res, bc : false })
            if(res===undefined){ return }
            this.setBreadcrumb()
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match } = this.props

        if(prevProps.update===true || prevProps.match.params.id!==match.params.id){
            this.componentDidMount()
        }
        
    }

    setBreadcrumb(){
        const { main } = this.props
        const { bc, product } = this.state
        const { setBreadcrumb, match } = this.props

        if(bc===true){ return }

        var title = (main.language)? main.language.MENU_PRODUCT : "-"
        var arr = [{name:title, link:"/product"}]

        if(match.params.hasOwnProperty("id")){
            var id = match.params.id
            var name = product.TITLE
            
            arr.push({name:name,link:"/product/"+id})
        }

        setBreadcrumb(title, arr)
        this.setState({ bc:true })
    }

    render() {
        const { product } = this.state
        if(product===undefined){ return <Redirect push to="/404" /> }
        return (
            <main>
                <div role="main" className="main">
                    <div className="page-content-wrapper section-space--inner--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div style={{float:"left",marginRight:"10px",width:"40%"}}>
                                        <img src={product.FULL_PATH_IMAGE} className="img-fluid" alt="" style={{width:"100%",height:"auto"}}/>
                                    </div>
                                    <h3 className="title">{product.TITLE}</h3>
                                    <h4 className="title">{product.SUB_TITLE}</h4>
                                    <h4 className="title">{product.NAME}</h4>
                                    <h4 className="title">Rp {formattingCurrency(product.PRICE)}{product.UNIT}</h4>
                                    { ReactHtmlParser(product.DESC_PRODUCTS) }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="float-right">
                                        <Share/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(ProductDetail)
