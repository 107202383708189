import { setLanguage } from "./request";

export const isLangEn = () => {
    return (localStorage.getItem("lang")==="en")? true : false
}

export const setLang = (lang) => {
    localStorage.setItem('lang', lang)
    window.paramLang = (localStorage.getItem("lang")==null)? "" : "?lang="+localStorage.getItem("lang")
    // window.paramLang = ""

    setLanguage(lang)
}
