import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';
import { getStoryLanding } from '../utils/request';

export class Story extends Component {
    constructor(props) {
        super(props);

        this.state = {
            story : [],
        };
    }

    componentDidMount() {
		getStoryLanding().then((res)=>{
			this.setState({ story : res })
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    render() {
		const { main } = this.props
		const { story } = this.state
        const title = (this.props.storyTag!==undefined)? this.props.storyTag.title
            : (main.language)? main.language.MENU_STORY : "-"
        return (
            <div className="page-content-wrapper section-space--inner--top--120" id="Story">
                <div className="container">
                    {(this.props.storyTag!==undefined && this.props.storyTag.removeHeader===true)?
                        <div></div>
                    :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-title-area text-center section-space--bottom--50">
                                    <h2 className="title">{ title }</h2>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common-page-content">
                                <div className="common-page-text-wrapper section-space--bottom--50">
                                    { ReactHtmlParser(main.HISTORY) }
                                </div>

                                <div className="history-grid__wrapper row">
                                    {story.map((s,i)=>(
                                        <div className="col-sm-6 history-grid__single-wrapper" key={i}>
                                            <div className="dot"></div>
                                            <div className="history-grid__single">
                                                <div className="content-head">
                                                    {s.YEARS} - {s.TITLE}
                                                </div>
                                                <div className="content-body">
                                                    <div className="image">
                                                        <img src={s.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                                    </div>
                                                    <div className="content">{ReactHtmlParser(s.DESC_STORY)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Story;