import React, { Component } from 'react'
import { withRouter } from "react-router"
import { appendScript, removeElement } from '../utils/dom'
import ReactHtmlParser from 'react-html-parser'
import { getDetailClient } from '../utils/request'
import { Redirect, Link, } from "react-router-dom"
import { Share, Product, PortofolioGrid, ServiceAccordion, TenantVideo, } from '../components'

export class TenantDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "TenantDetailScript",
                src : "/js/component/TenantDetail.js",
                async : true,
            },
            remove : ".TenantDetailScript", // string or array
            tenant : {},
			update: false,
            bc : false,
        };
    }

    componentDidMount() {
        const { match } = this.props
        const { attrs, remove } = this.state
        
		getDetailClient({slug:match.params.id}).then((res)=>{
            this.setState({ tenant : res, bc : false })
            if(res===undefined){ return }
            this.setBreadcrumb()
            appendScript(attrs,remove)
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match } = this.props

        if(prevProps.update===true || prevProps.match.params.id!==match.params.id){
            this.componentDidMount()
        }
        
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }

    setBreadcrumb(){
        const { main } = this.props
        const { bc, tenant } = this.state
        const { setBreadcrumb, match } = this.props

        if(bc===true){ return }

        var title = (main.language)? main.language.MENU_TENANT : "-"
        var arr = [{name:title, link:"/tenant"}]

        if(match.params.hasOwnProperty("id")){
            var id = match.params.id
            var name = tenant.NAME
            
            arr.push({name:name,link:"/tenant/"+id})
        }

        setBreadcrumb(title, arr)
        this.setState({ bc:true })
    }

    render() {
        const { tenant } = this.state
        const { main } = this.props
        
        if(tenant===undefined){ return <Redirect push to="/404" /> }
		if(tenant.ID===undefined){ return("") }
        return (
            <main>
                <style>{`
                        .portofolioContent .olshop li {
                            width : 100%; }
                        .portofolioContent .olshop a {
                            font-weight : 700; }
                        .portofolioContent .olshop li:nth-child(1) a {
                            color : #5eaa4d; }
                        .portofolioContent .olshop li:nth-child(2) a {
                            color : #e31e52; }
                        .portofolioContent .olshop li:nth-child(3) a {
                            color : #f1582c; }
                        .portofolioContent .olshop a img {
                            width : 30px; }
                        .portofolioContent .olshop a span {
                            position : absolute;
                            bottom : 0;
                            margin-left : 3px; }
                `}</style>
                <div role="main" className="main">
                    <div className="page-content-wrapper section-space--inner--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 portofolioContent">
                                    <div style={{float:"left",marginRight:"10px",width:"40%"}}>
                                        <img src={tenant.FULL_PATH_IMAGE} className="img-fluid" alt="" style={{width:"100%",height:"auto"}} />
                                        <div className="row mt-1">
                                            <div className="col-lg-12">
                                                <Link to={{pathname:"https://www.google.com/maps/@"+tenant.LAT+","+tenant.LON+",15z"}} target="_blank" id="address" title={(main.language)? main.language.ADDRESS : "-"}>
                                                    { ReactHtmlParser(tenant.ADDRESS) }
                                                </Link>
                                                <ul className="list-group list-group-horizontal no-border" style={{ justifyContent:"center" }}>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:"https://wa.me/"+tenant.PHONE_NO}} target="_blank" title="Whatsapp">
                                                            <span className="ion-social-whatsapp"></span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:"mailto:"+tenant.EMAIL}} target="_blank" title="Email">
                                                            <span className="fa fa-envelope"></span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:tenant.LINK_IG}} target="_blank" title="Instagram">
                                                            <span className="ion-social-instagram"></span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:tenant.LINK_YT}} target="_blank" title="Youtube">
                                                            <span className="ion-social-youtube"></span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:tenant.LINK_FB}} target="_blank" title="Facebook">
                                                            <span className="ion-social-facebook"></span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:tenant.LINK_LINKEDIN}} target="_blank" title="LinkedIn">
                                                            <span className="ion-social-linkedin"></span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:tenant.LINK_WEBSITE}} target="_blank" title="Website">
                                                            <span className="fa fa-globe"></span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                                <ul className="list-group list-group-horizontal no-border olshop" style={{ justifyContent:"center" }}>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:tenant.LINK_TOKPED}} target="_blank" title="Tokopedia" className="p-0 m-0">
                                                            <img src={"../assets/img/icons/tokopedia.png"} className="img-fluid" alt=""/>
                                                            <span>TOKOPEDIA</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:tenant.LINK_BUKALAPAK}} target="_blank" title="Bukalapak" className="p-0 m-0">
                                                            <img src={"../assets/img/icons/bukalapak.png"} className="img-fluid" alt=""/>
                                                            <span>BUKALAPAK</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item no-border pt-1 pb-1">
                                                        <Link to={{pathname:tenant.LINK_SHOPEE}} target="_blank" title="Shopee" className="p-0 m-0">
                                                            <img src={"../assets/img/icons/shopee.png"} className="img-fluid" alt=""/>
                                                            <span>SHOPEE</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                                <hr width="90%"/>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="title">{tenant.NAME}</h3>
                                    <a href={"mailto:"+tenant.EMAIL} rel="noreferrer" target="_blank">{tenant.EMAIL}</a>
                                    <a href={"tel:"+tenant.PHONE_NO} rel="noreferrer" target="_blank">{tenant.PHONE_NO}</a>
                                    { ReactHtmlParser(tenant.DESCRIPTION) }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 portofolioContent">
                                    <TenantVideo url={tenant.LINK_EMBED_YT} {...this.props}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 portofolioContent">
                                    <Product ClientId={tenant.ID} allowRemoveOnEmpty={true} {...this.props} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 portofolioContent">
                                    <PortofolioGrid ClientId={tenant.ID} portfolioTag={{removeHeader:true}} {...this.props}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 portofolioContent">
                                    <ServiceAccordion ClientId={tenant.ID} {...this.props}/>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12">
                                    <div className="float-right">
                                        <Share/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(TenantDetail)
