import React, { Component } from "react";
import { appendScript, removeElement } from '../utils/dom';
import { getBanner } from '../utils/request';

export class NewestEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "NewestEventScript",
                src : "/js/component/NewestEvent.js",
                async : true,
            },
            remove : ".NewestEventScript", // string or array
            sliders : [],
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
        getBanner().then((res)=>{
            this.setState({ sliders : res })
            appendScript(attrs,remove)
        })
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    render() {
        const { sliders } = this.state
        return (
            <div className="hero-slider-area" id="NewestEvent">
                <div id="rev_slider_2_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="home" data-source="gallery" style={{ margin:'0px auto', background:'transparent', padding:'0px', marginTop:'0px', marginBottom:'0px', }}>
                    <div id="rev_slider_2_1" className="rev_slider fullwidthabanner" data-version="5.4.7" style={{display:'none',}}>
                        <ul>
                            {sliders.map((s,i)=>(
                                <li key={"newest-event-"+i} data-index={"rs-"+i} data-transition="incube-horizontal" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                                    <img src={s.FULL_PATH_IMAGE} alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina/>
                
                                    <div className="tp-caption rev_group" id={"slide-"+i+"-layer-5"} data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="['988','772','595','99%']" data-height="['454','453','404','347']" data-whitespace="nowrap" data-type="group" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: "5", minWidth: '988px', maxWidth: '988px', whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px'}}>
                
                                        <div className="tp-caption" id={"slide-"+i+"-layer-2"} data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']" data-voffset="['70','90','74','72']" data-fontsize="['90','70','60','40']" data-lineheight="['105','85','75','54']" data-fontweight="['900','900','700','900']" data-width="['800','600','500','350']" data-height="none" data-whitespace="normal" data-type="text" data-responsive_offset="off" data-responsive="off" data-frames='[{"delay":"+930","speed":300,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: "6", minWidth: '800px', maxWidth: '800px', whiteSpace: 'normal', fontSize: '90px', lineHeight: '105px', fontWeight: 900, color: '#ffffff', letterSpacing: '0px',fontFamily:'Roboto',}}>{s.TITLE}</div>
                
                                        <div className="tp-caption" id={"slide-"+i+"-layer-1"} data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']" data-voffset="['0','0','0','0']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="off" data-responsive="off" data-frames='[{"delay":"+620","speed":300,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[10,10,10,10]" data-paddingright="[10,10,10,10]" data-paddingbottom="[10,10,10,10]" data-paddingleft="[10,10,10,10]" style={{zIndex: "7", whiteSpace: 'nowrap', fontSize: '12px', lineHeight: '12px', fontWeight: 700, color: '#ffffff', letterSpacing: '2px',fontFamily:'Roboto',textTransform:'uppercase',borderColor:'rgba(255,255,255,0.2)',borderStyle:'solid',borderWidth:'1px 1px 1px 1px',}}>Building up vision leading future </div>
                
                                        <div className="tp-caption" id={"slide-"+i+"-layer-3"} data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['107','107','108','83']" data-fontsize="['18','18','18','16']" data-lineheight="['18','18','18','28']" data-width="['none','none','none','350']" data-height="none" data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-responsive_offset="off" data-responsive="off" data-frames='[{"delay":"+1340","speed":300,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,30]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,30]" style={{zIndex: "8", whiteSpace: 'nowrap', fontSize: '18px', lineHeight: '18px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px',fontFamily:'Roboto',}}>{s.SUB_TITLE}</div>
                
                                        <a className="tp-caption rev-btn-01 rev-btn" href="/home" target="_self" id={"slide-"+i+"-layer-4"} data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="button" data-actions='' data-responsive_offset="off" data-responsive="off" data-frames='[{"delay":"+1620","speed":300,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(34,34,34);bs:solid;bw:0 0 0 0;"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[20,20,20,20]" data-paddingright="[30,30,30,30]" data-paddingbottom="[20,20,20,20]" data-paddingleft="[30,30,30,30]" style={{zIndex: "9", whiteSpace: 'nowrap', letterSpacing: '1px',textTransform:'uppercase',borderColor:'rgba(0,0,0,1)',outline:'none',boxShadow:'none',boxSizing:'border-box',MozBoxSizing:'border-box',WebkitBoxSizing:'border-box',cursor:'pointer',textDecoration: 'none',}}>Our industries </a>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="tp-bannertimer" style={{ height: '5px', background: 'rgba(0,0,0,0.15)' }}></div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NewestEvent;