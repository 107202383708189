import React, { Component } from "react";
import { appendScript, removeElement } from '../utils/dom';
import { getDocumentLanding } from '../utils/request';
import { Link } from 'react-router-dom'

export class Download extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs :{
                class : "DownloadScript",
                src : "/js/component/Download.js",
                async : true,
            },
            remove : ".DownloadScript", // string or array
            docs : [],
        };
    }

    componentDidMount() {
        const { attrs, remove } = this.state
		getDocumentLanding().then((res)=>{
            this.setState({ docs : res })
            appendScript(attrs,remove)
		})
    }

    componentWillUnmount(){
        const { remove } = this.state
        removeElement(remove)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.update===true){
            this.componentDidMount()
        }
    }

    render() {
        const { docs } = this.state
        const { main } = this.props
        const title = (this.props.downloadTag!==undefined)? this.props.downloadTag.title
            : (main.language)? main.language.MENU_DOWNLOAD : "-"
        return (
            <div className="industry-icon-area section-space--inner--120 grey-bg--style3" id="Download">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area section-title-area--style3 section-space--bottom--80">
                                <h2 className="title title--style7"> {title}</h2>
                                <div className="swiper-static-nav-wrap">
                                    <div className="ht-swiper-button-prev ht-swiper-button-prev-11 ht-swiper-button-nav"><i className="ion-chevron-left"></i></div>
                                    <div className="ht-swiper-button-next ht-swiper-button-next-11 ht-swiper-button-nav"><i className="ion-chevron-right"></i></div>
                                </div>
                            </div>
                            <div className="industry-icon__container-area">
                                <div className="swiper-container industry-icon__container">
                                    <div className="swiper-wrapper industry-icon__wrapper">
                                        {docs.map((d,i)=>(
                                            <div className="swiper-slide industry-icon__single-slide" key={i}>
                                                <div className="single-industry-icon single-industry-icon--style2">
                                                    <div className="single-industry-icon__image">
                                                        <img src={"assets/img/icons/downloads/"+d.FULL_PATH_DOC.split(".").at(-1)+".png"} className="img-fluid" alt="" style={{width:"50px"}} credit-to="https://www.flaticon.com/authors/webalys"/>
                                                    </div>
                                                    <div className="single-industry-icon__content">
                                                        <div className="text-wrapper">
                                                            <h3 className="title">
                                                                <Link to={{pathname:`${d.FULL_PATH_DOC}`}} target="_blank">{d.NAME}</Link>
                                                            </h3>
                                                            <p className="text">{d.DESC_DOC.substr(0,300)}</p>
                                                        </div>
                                                        <Link to={{pathname:`${d.FULL_PATH_DOC}`}} className="see-more-link see-more-link--dark see-more-link--dark--style2" target="_blank">Download</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Download;