import React, { Component } from "react";
import { getNews } from '../utils/request';
import { Link } from 'react-router-dom'
import { scrollTo } from "../utils/global";
import { paginationDom, } from "../utils/dom";
import $ from 'jquery'
import { withRouter } from "react-router";

export class Activity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acts : [],
            total : 0,
            limit : 9,
            currentPage : 1,
            category : 1,
            btnPages : [],
            update : false,
        };

		this.goTo = this.goTo.bind(this)
    }

    componentDidMount() {
        var btnPages = []
        const { limit, currentPage, category, } = this.state
		getNews({category:category, limit:limit, pagin:currentPage}).then((res)=>{
            for(var i=1; i<=Math.ceil(res.total/limit); i++){
                btnPages.push(i)
            }
            this.setState({
                acts:res.news,
                total:res.total,
                btnPages:btnPages,
            })
		})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { update, } = this.state
		if(prevProps.update===true || update===true){
            this.componentDidMount()
            this.setState({ update:false })
        }
    }

    goTo(page){
        const { update } = this.state
        this.setState({ currentPage:page, update:true })
        setTimeout(function(){
            if(update===false)
                scrollTo({target:$("#Activity")[0]})
        },500)
    }

    render() {
        const { location, main, } = this.props
        const { acts, currentPage, btnPages, } = this.state
        const title = (this.props.activityTag!==undefined)? this.props.activityTag.title
            : (main.language)? main.language.MENU_ACTIVITY : "-"
        return (
            <div className="page-content-wrapper section-space--inner--top--120 grey-bg" id="Activity" href={location.pathname+"#Activity"}>
                <div className="container">
                    {(this.props.activityTag!==undefined && this.props.activityTag.removeHeader===true)?
                        <div></div>
                    :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-title-area text-center section-space--bottom--50">
                                    <h2 className="title">{ title }</h2>
                                    <p className="subtitle">Professional ethics govern the values of social responsibility that help us deliver our obligations within a coherent social framework.</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="category-grid-wrapper section-space--bottom--m30">
                        <div className="row">
                            {(acts.length<=6 || window.navigator.userAgentData.mobile===true)?
                                (<div className="col-lg-8 text-right"></div>)
                            :
                                (<div className="col-lg-8 text-right">
                                    {paginationDom(currentPage,btnPages,this.goTo)}
                                </div>)
                            }
                        </div>
                        <div className="row">
                            {acts.map((a,i)=>(
                                <div className="col-lg-4 col-md-6" key={i}>
                                    <div className="single-case-study-project">
                                        <Link to={"/activity/"+a.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }} style={{width:"100%",height:"100%"}}>
                                            <div className="single-case-study-project__image">
                                                <img src={a.FULL_PATH_IMAGE} className="img-fluid" alt=""/>
                                            </div>
                                        </Link>
                                        <div className="single-case-study-project__content">
                                            <h3 className="title">
                                                <Link to={"/activity/"+a.SLUG} target="_self" onClick={(el)=>{ scrollTo(el) }}>{a.TITLE}</Link>
                                            </h3>
                                            <p>{a.SUB_TITLE}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row section-space--inner--bottom--120">
                            <div className="col text-center">
                                {paginationDom(currentPage,btnPages,this.goTo)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Activity);